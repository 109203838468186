import React, {Component} from 'react';

class PromoTwo extends Component {
  state = {
    promoData_1: [],
  };

  render () {
    return (
      <section className="section promo-area ptb_100">
        <div className="container">
          <div className="row">
            <div class="col-12 col-md-12 col-lg-4 res-margin">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper">
                  <h3 class="mb-3">Zero to Hero</h3>
                  <p>
                    Begin your journey with us and rapidly evolve from novice to expert with our comprehensive tools and resources designed to take you from zero to hero in no time. Embark on a transformative learning experience that paves your way to mastery.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4 res-margin">
              <div class="single-promo grad-hover active text-center p-5">
                <div class="promo-wrapper">
                  <h3 class="mb-3">Dedicated Support</h3>
                  <p>
                    Experience peace of mind with our dedicated support team, committed to providing you with 24/7 assistance. Whether you're tackling technical difficulties or seeking guidance, our expert support staff is always on hand to ensure you receive the help you need, whenever you need it.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4 res-margin">
              <div class="single-promo grad-hover text-center p-5">
                <div class="promo-wrapper">
                  <h3 class="mb-3">100% Up-time</h3>
                  <p>
                    Reliability is paramount, which is why we take pride in offering a service with 100% up-time. Our robust infrastructure is built to keep you online and operational, ensuring that your work proceeds without interruption at all times.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PromoTwo;
