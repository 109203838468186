import React from 'react';

const Scrollup = () => {
  return (
    <div id="scrollUp" title="Scroll To Top">
      <i className="fas fa-arrow-up" />
    </div>
  );
};

export default Scrollup;
