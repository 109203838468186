import {
  Divider,
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import axiosApiInstance from "../../store/axiosApi";
import BasicModal from "../BasicModal/modal";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import Footer from "../Footer";
import Header from "../Header/HeaderOne";
import LoadingDialog from "../Loading";
import ModalMenuSection from "../Modal/ModalMenu";
import ModalSearchSection from "../Modal/ModalSearch";
import ScrollupSection from "../Scrollup/Scrollup";

const AffilateSubscription = () => {
  const [loading, setLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [token, setToken] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogContent, setConfirmationDialogContent] =
    useState("");
  const [confirmationDialogAction, setConfirmationDialogAction] = useState(
    () => {}
  );
  const [cancelText, setCancelText] = useState("");
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");
    setToken(token);
  }, []);

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const fetchSubscriptions = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post("/getSubscriptions", {
        token: token,
      });
      setSubscriptions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      setLoading(false);
      showModal("Error", "Failed to fetch subscriptions.");
    }
  };

  const handleExtend = async (subscriptionId) => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.post("/createNewSubscription", {
        token,
      });

      if (response.status == 200) {
        window.location.href = response.data.redirectUrl;
      } else {
        showModal("Error", "Failed to extend subscription.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error extending subscription:", error);
      showModal("Error", "Failed to extend subscription.");
    }
  };

  const handleCancelExtend = async (subscriptionId) => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.post("/cancelSubscription", {
        token,
        subscriptionId,
      });
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error("Error extending subscription:", error);
      showModal("Error", "Failed to extend subscription.");
    }
  };

  const handleConfirmAction = async (action) => {
    setConfirmationDialogOpen(false);
    await action();
  };

  const handleCancel = (subscriptionId) => {
    setConfirmationDialogContent(
      "Canceling your subscription will also cancel your participation in the affiliate marketing program and any further affiliate commissions. Are you sure you want to cancel your subscription?"
    );
    setConfirmationDialogAction(() => () => handleCancelExtend(subscriptionId));
    setCancelText("Back");
    setConfirmText("Confirm Cancellation");
    setConfirmationDialogOpen(true);
  };

  const handleRenew = (subscriptionId) => {
    setConfirmationDialogContent(
      "Are you sure you want to renew your subscription?"
    );
    setConfirmationDialogAction(() => () => handleExtend(subscriptionId));
    setCancelText("Cancel");
    setConfirmText("Confirm");
    setConfirmationDialogOpen(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <div>
      <ScrollupSection />
      <CssBaseline />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
            heading={"AFFILIATE DASHBOARD"}
            home={"Home"}
            page={"Dashboard"}
            title={"SUBSCRIPTION"}
            pageLink={`/dashboard?token=${token}`}
            showBreadcrumb="true"
          />
        <Container component="main" sx={{ mb: 4 }} className="mainContainer">
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Your Monthly Subscription Status
            </Typography>
            {loading ? (
              <LoadingDialog title="Loading..." />
            ) : subscriptions.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Subscription Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Next Payment</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptions.map((subscription) => (
                      <TableRow key={subscription.sp_subscription_id}>
                        <TableCell>{subscription.email}</TableCell>
                        <TableCell>
                          {subscription.sp_status === "canceled"
                            ? `Canceled (${formatDate(
                                subscription.last_extended_at
                              )})`
                            : subscription.is_canceled
                            ? `Canceled (${subscription.canceled_at.substring(
                                0,
                                10
                              )})`
                            : subscription.sp_status}
                        </TableCell>
                        <TableCell>
                          {formatDate(subscription.last_extended_at)}
                        </TableCell>
                        <TableCell>
                          {subscription.sp_status !== "active" ||
                          subscription.is_canceled ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleRenew(subscription.sp_subscription_id)
                              }
                            >
                              Renew
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleCancel(subscription.sp_subscription_id)
                              }
                            >
                              Cancel
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" align="center" sx={{ mt: 3 }}>
                No subscriptions found.
              </Typography>
            )}
          </Paper>
          <BasicModal
            open={modalOpen}
            title={modalTitle}
            content={modalContent}
            showButton={false}
            handleClose={() => setModalOpen(false)}
            handleClick={() => setModalOpen(false)}
          />
          <ConfirmationDialog
            open={confirmationDialogOpen}
            title="Confirmation"
            content={confirmationDialogContent}
            cancel={cancelText}
            confirm={confirmText}
            onConfirm={() => handleConfirmAction(confirmationDialogAction)}
            onCancel={() => setConfirmationDialogOpen(false)}
          />
        </Container>
        <ModalSearchSection />
        <ModalMenuSection />
        <Footer />
      </div>
    </div>
  );
};

const ConfirmationDialog = ({ open, title, content, cancel, confirm, onConfirm, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {cancel}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AffilateSubscription;
