import React, { Component } from "react";

import ScrollupSection from "../components/Scrollup/Scrollup";
import Header from "../components/Header/HeaderOne";
import HeroSection from "../components/Hero/HeroThree";
import PromoSection from "../components/Promo/PromoTwo";
import ModalSearchSection from "../components/Modal/ModalSearch";
import ModalMenuSection from "../components/Modal/ModalMenu";
import { useParams } from "react-router-dom";

const LayoutTheme = () => {
  const { id } = useParams();
  return (
    <div className="homepage-3">
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <HeroSection acCode={id} />
        {/* <PromoSection /> */}
        <ModalMenuSection />
      </div>
    </div>
  );
};

export default LayoutTheme;
