import React, { Component } from "react";

import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalMenuSection from "../Modal/ModalMenu";

export default function Stripe() {
  const [customQrPreview, setCustomQrPreview] = useState();
  const [qrError, setQRError] = useState(false);

  const fetchData = async () => {
    const queryString = window.location.search;
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(queryString);
    // Get the value of a specific query parameter
    const session_id = queryParams.get("session_id");
    const userId = queryParams.get("userid");
    const delta = queryParams.get("delta");

    setQRError(false);
    const postResponse = await axiosApiInstance.post("/verify_stripe", {
      session_id: session_id,
      userId: userId,
      delta: delta,
    });
    if (postResponse.data.status == "error") {
      setQRError(true);
      return;
    }
    setQRError(false);
    setCustomQrPreview(postResponse.data.imgData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"PURCHASE COMPLETED!"}
          home={"Home"}
          page={"Purchase Page"}
          title={"PURCHASE"}
        />

        {!qrError ? (
          <div>
            {customQrPreview ? (
              <div>
                <div>
                  <img
                    src={customQrPreview}
                    style={{
                      width: 350,
                    }}
                    alt="processed"
                    title="processed"
                  />
                </div>
                <div>
                  <a
                    href={customQrPreview}
                    download="MyQR.jpg"
                    className="btn btn-success btn-submit"
                    style={{
                      width: "200px",
                      color: "white",
                      marginBottom: "15px",
                      marginTop: "15px",
                    }}
                  >
                    Download Image
                  </a>
                </div>
                <Link
                  className="btn btn-primary"
                  to={{
                    pathname: "/",
                  }}
                  style={{
                    width: "200px",
                    color: "white",
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  Home
                </Link>
              </div>
            ) : (
              <div>
                <LoadingDialog title="Thanks for your purchase generating your QR" />
              </div>
            )}
          </div>
        ) : (
          <div>
            <p>Something went wrong. Please click the refresh button.</p>
            <button
              onClick={() => fetchData()}
              className="btn btn-danger btn-submit"
              style={{
                width: "200px",
                color: "white",
                marginBottom: "15px",
                marginTop: "15px",
              }}
            >
              Refresh
            </button>
          </div>
        )}

        <ModalMenuSection />
      </div>
    </div>
  );
}
