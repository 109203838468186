import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosApiInstance from "../../store/axiosApi";
import LoadingDialog from "./modal";
import constants from "../../config/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AffiliateLogin({ opend, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("info");
  const [buttonText, setButtonText] = useState("Send");
  const handleSend = async () => {
    try {
      if (emailAddress == "") {
        setResponseType("error");
        setResponseMessage("Please input your email address.");
        return;
      }

      setIsLoading(true);
      const response = await axiosApiInstance.post("/generate_link", {
        email: emailAddress,
      });

      if (response.data.success) {
        setResponseType("info");
        setResponseMessage(response.data.message);
        setIsLoading(false);
        setButtonText("Resend");
      } else {
        setResponseType("error");
        setResponseMessage(error.response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setResponseType("error");
      if (error.response != null && error.response.status == 401)
        setResponseMessage(error.response.data.message);
      else setResponseMessage(constants.serverConnectionErrMsg);
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <Modal
        open={opend}
        onClose={() => {
          setEmailAddress("");
          setResponseMessage("");
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please input your email address.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You'll receive a new dashboard link to your email.
          </Typography>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="standard"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {responseMessage && (
            <Alert severity={responseType} style={{ marginTop: "15px" }}>
              {responseMessage}
            </Alert>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={isLoading}
              sx={{ mt: 3, ml: 1 }}
              variant="contained"
              onClick={() => {
                handleSend();
              }}
            >
              {buttonText}
            </LoadingButton>
            <Button
              className="btn-checkout"
              sx={{ mt: 3, ml: 1 }}
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      {isLoading ? <LoadingDialog title="loading..." /> : <div />}
    </div>
  );
}
