import React, { useState, useEffect, useCallback } from "react";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import LoadingDialog from "../Loading";
import Footer from "../Footer";
import {
  CssBaseline,
  TextField,
  Button,
  Container,
  Paper,
  Grid,
} from "@mui/material";
import BasicModal from "../BasicModal/affiliate_modal";
import axiosApiInstance from "../../store/axiosApi";

export default function ReorderQR() {
  const [isLoading, setIsLoading] = useState(false);
  const [qrData, setQRData] = useState("");
  const [labelText, setLabelText] = useState("The QR Store");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showButton, setShowButton] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const handleReorder = async () => {
    try {
      setIsLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");

      const response = await axiosApiInstance.post("/reorderQR", {
        token: token,
      });
      if (response.status == 200) {
        window.open(response.data.redirectUrl, "_blank");
      } else {
        showModal("Error", "Failed to reorder QR code tags.");
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showModal("Error", "Failed to reorder QR code tags.");
    }
  };

  const fetchData = async () => {
    try {
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      setIsLoading(true);
      const response = await axiosApiInstance.post("/profile", {
        token: token,
      });
      console.log(response);
      if (response.data.success) {
        setQRData(response.data.user.qrData);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle the error here. Could be a simple console log or error message display.
      console.error(error);
      setIsLoading(false);
    }
  };

  const showModal = (title, content, showed = false) => {
    setShowButton(false);
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  return (
    <div>
      {" "}
      {isLoading ? <LoadingDialog /> : <div />}
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"Reorder"}
          home={"Home"}
          page={"Purchase Page"}
          title={"Personalized QR"}
        />
        <React.Fragment>
          <CssBaseline />
          <Container component="main" className="checkout">
            <Grid
              container
              spacing={2}
              sx={{ display: { xs: "block", sm: "flex" } }}
            >
              <Grid item xs={12} sm={8}>
                <Paper
                  variant="outlined"
                  sx={{
                    my: { xs: 3, md: 2 },
                    p: { xs: 2, md: 3 },
                    minHeight: "400px",
                  }}
                >
                  <p className="label-text-notify">
                    Please enter the text you would like to see at the top of
                    your QR tag.
                  </p>
                  <TextField
                    required
                    id="qr_label"
                    name="QR Label"
                    label="QR Label Text"
                    fullWidth
                    autoComplete="given-name"
                    variant="outlined"
                    value={labelText}
                    onChange={(e) => setLabelText(e.target.value)}
                    inputProps={{
                      maxLength: 20,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    sx={{ mb: 2 }}
                  />
                  <p>
                    Enter up to 20 characters to personalize your QR tag or
                    leave "The QR Store" as the default.
                  </p>
                  <div className="button-text">
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-checkout btn-custom-prev-next"
                      onClick={handleReorder}
                      sx={{ mt: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div id="qrlabel-area">
                  <h3 className="qrlabel-preview-text">{labelText}</h3>
                  <img
                    src={`data:image/png;base64,${qrData}`}
                    alt="QR Code"
                    className="qr-preview-img"
                  />
                </div>
              </Grid>
            </Grid>
            <BasicModal
              open={modalOpen}
              title={modalTitle}
              content={modalContent}
              showButton={showButton}
              handleClose={() => {
                setModalOpen(false);
              }}
              handleClick={() => {
                setModalOpen(false);
              }}
            />
          </Container>
        </React.Fragment>
        <ModalSearchSection />
        <ModalMenuSection />
      </div>
    </div>
  );
}
