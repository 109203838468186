import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Typography from "@mui/material/Typography";

export default function ServiceChoose() {
  const [query, setQuery] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(queryString);
    // Get the value of a specific query parameter
    const acCode = queryParams.get("ac");
    setQuery(acCode);
  }, []);

  return (
    <section className="section content-area" style={{ marginTop: "75px" }}>
      <div class="container">
        <h3>What type of QR do you want to create?</h3>
        <div className="choose-system">
          <h3>
            <Link
              to={{
                pathname: "/purchase",
                search:
                  query != null
                    ? `?ac=${query}&type=personalized`
                    : "type=personalized",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-qr-code"
                viewBox="0 0 16 16"
              >
                <path d="M2 2h2v2H2V2Z" />
                <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
              </svg>
              Contact QR
            </Link>
          </h3>
          <h5 className="choose-system-title">
            A contact QR allows you to quickly and easily share your contact
            infromation with others.
          </h5>
        </div>
        <div className="choose-system">
          <h3>
            <Link
              to={{
                pathname: "/purchase",
                search:
                  query != null
                    ? `?ac=${query}&type=promotional`
                    : "type=promotional",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-qr-code"
                viewBox="0 0 16 16"
              >
                <path d="M2 2h2v2H2V2Z" />
                <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
              </svg>
              Promotional QR
            </Link>
          </h3>
          <h5 className="choose-system-title">
            A promotional QR allows you to share your web site and social media
            for people to learn more about your business, products or services.
          </h5>
        </div>
      </div>
    </section>
  );
}
