import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: 0,
  firstName: "",
  lastName: "",
  organization: "",
  positionWork: "",
  phoneWork: "",
  confirmPhoneWork: "",
  phoneMobile: "",
  confirmPhoneMobile: "",
  email: "",
  confirmEmail: "",
  website: "",
  street: "",
  zipCode: "",
  city: "",
  naState: "",
  country: "USA",
  isLoading: false,
  loadingTitle: "",
  processedImage: "",
  userId: "",
  selectedFile: "",
  preview: "",
  foregroundPreview: "",
  customQrPreview: "",
  checked: false,
  selectedLocationOption: "right",
  errors: {
    email: "",
    confirmEmail: "",
    phoneMobile: "",
    confirmPhoneMobile: "",
    status: true,
    phoneWork: "",
    confirmPhoneWork: "",
  },
  personalLink1Title: "",
  personalLink1: "",
  personalLink2Title: "",
  personalLink2: "",
  personalLink3Title: "",
  personalLink3: "",
  affiliateLink: "",
  avatar: "",
  qrData: "",
  showAdditionalContent: false,
  labelText: "",
};

export const contactInfoSlice = createSlice({
  name: "person",
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
    setPositionWork: (state, action) => {
      state.positionWork = action.payload;
    },
    setPhoneWork: (state, action) => {
      state.phoneWork = action.payload;
    },
    setPhoneMobile: (state, action) => {
      state.phoneMobile = action.payload;
    },
    setConfirmPhoneMobile: (state, action) => {
      state.confirmPhoneMobile = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setConfirmEmail: (state, action) => {
      state.confirmEmail = action.payload;
    },
    setWebsite: (state, action) => {
      state.website = action.payload;
    },
    setStreet: (state, action) => {
      state.street = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setNaState: (state, action) => {
      state.naState = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoadingTitle: (state, action) => {
      state.loadingTitle = action.payload;
    },
    setSelectLocationOption: (state, action) => {
      state.selectedLocationOption = action.payload;
    },
    setChecked: (state, action) => {
      state.checked = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setForegroundPreview: (state, action) => {
      state.foregroundPreview = action.payload;
    },
    setCustomQrPreview: (state, action) => {
      state.customQrPreview = action.payload;
    },
    setPreview: (state, action) => {
      state.preview = action.payload;
    },
    setQrData: (state, action) => {
      state.qrData = action.payload;
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setPersonalLink1: (state, action) => {
      state.personalLink1 = action.payload;
    },
    setPersonalLink1Title: (state, action) => {
      state.personalLink1Title = action.payload;
    },
    setPersonalLink2: (state, action) => {
      state.personalLink2 = action.payload;
    },
    setPersonalLink2Title: (state, action) => {
      state.personalLink2Title = action.payload;
    },
    setPersonalLink3: (state, action) => {
      state.personalLink3 = action.payload;
    },
    setPersonalLink3Title: (state, action) => {
      state.personalLink3Title = action.payload;
    },
    setAffiliateLink: (state, action) => {
      state.affiliateLink = action.payload;
    },
    setConfirmPhoneWork: (state, action) => {
      state.confirmPhoneWork = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setShowAdditionalContent: (state, action) => {
      state.showAdditionalContent = action.payload;
    },
    setPerson: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setOrganization,
  setPositionWork,
  setConfirmPhoneWork,
  setPhoneWork,
  setPhoneMobile,
  setConfirmPhoneMobile,
  setEmail,
  setConfirmEmail,
  setWebsite,
  setStreet,
  setZipCode,
  setCity,
  setNaState,
  setCountry,
  setErrors,
  setLoading,
  setLoadingTitle,
  setSelectLocationOption,
  setChecked,
  setUserId,
  setForegroundPreview,
  setCustomQrPreview,
  setPreview,
  setQrData,
  setSelectedFile,
  setPerson,
  setPersonalLink1Title,
  setPersonalLink1,
  setPersonalLink2Title,
  setPersonalLink2,
  setPersonalLink3Title,
  setPersonalLink3,
  setActiveStep,
  setAvatar,
  setShowAdditionalContent,
} = contactInfoSlice.actions;

export default contactInfoSlice.reducer;
