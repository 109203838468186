import React, { useState, useEffect, useCallback, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AddressForm from "./AddressForm";
import CustomQR from "./CustomQR";
import PurchaseForm from "./PurchaseForm";
import axiosApiInstance from "../../store/axiosApi";
import BasicModal from "../BasicModal/modal";
import LoadingDialog from "../Loading";
import validateEmailAndPhone from "../Validate";
import { useSelector, useDispatch } from "react-redux";
import Resizer from "react-image-file-resizer";
import PromotionContainer from "../PromotionalQR/PromotionContainer";
import Footer from "../Footer";
import ContactPreview from "./ContactPreview";
import {
  setFirstName,
  setLastName,
  setOrganization,
  setPositionWork,
  setPhoneWork,
  setPhoneMobile,
  setConfirmPhoneMobile,
  setEmail,
  setConfirmEmail,
  setWebsite,
  setStreet,
  setZipCode,
  setCity,
  setNaState,
  setCountry,
  setErrors,
  setLoading,
  setLoadingTitle,
  setSelectLocationOption,
  setChecked,
  setUserId,
  setForegroundPreview,
  setCustomQrPreview,
  setPreview,
  setSelectedFile,
  setPerson,
  setActiveStep,
  setShowAdditionalContent,
} from "../../store/contactInfoSlice";

// const steps = ["Create Contact", "Personalize QR", "Purchase"];

export default function Checkout() {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.person);
  const activeStep = useSelector((state) => state.person.activeStep);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showButton, setShowButton] = useState("");
  const [steps, setSteps] = useState(["Create Contact", "Personalized QR"]);

  const mainContainerRef = useRef(null);

  const [isMobileView, setIsMobileView] = useState(false);
  const [activeMobileView, setActiveMobileView] = useState("content");
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("personData"));
    if (savedData) {
      dispatch(setPerson(savedData));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("personData", JSON.stringify({ person }));
  }, [activeStep]);

  const handleNext = (e) => {
    e.preventDefault();
    if (activeStep == 0) {
      createPreviewQR();
    } else if (activeStep == 1) {
      if (person.userId != null) {
        // setActiveStep (activeStep + 1);
        // handleStripeGateway();
      } else {
        showModal("Notification", "Input all informations.");
      }
    } else {
      handleStripeGateway();
    }
    return;
  };

  const handleBack = () => {
    dispatch(setActiveStep(activeStep - 1));
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddressForm />;
      case 1:
        return <CustomQR showModal={showModal} />;
      case 2:
        return <PurchaseForm />;
      default:
        throw new Error("Unknown step");
    }
  };

  const getButtonText = (step) => {
    switch (step) {
      case 0:
        return "Next";
      case 1:
        return "Purchase";
      case 2:
        return "Go to Stripe";
      default:
        throw "Next";
    }
  };

  const showModal = (title, content, showed = false) => {
    setShowButton(false);
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const resizeImgFile = (fileOrUrl) =>
    new Promise((resolve, reject) => {
      if (typeof fileOrUrl === "string") {
        // Handle URL case
        fetch(fileOrUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const fileType = blob.type.includes("png") ? "PNG" : "JPEG";
            Resizer.imageFileResizer(
              blob,
              350,
              350,
              "JPEG",
              100,
              0,
              (uri) => {
                resolve(uri);
              },
              "base64"
            );
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // Handle File case
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            let newWidth;
            let newHeight;

            if (width > height) {
              newWidth = 350;
              newHeight = undefined;
            } else {
              newWidth = undefined;
              newHeight = 350;
            }

            const fileType = fileOrUrl.type.includes("png") ? "PNG" : "JPEG";
            Resizer.imageFileResizer(
              fileOrUrl,
              newWidth,
              newHeight,
              fileType,
              100,
              0,
              (uri) => {
                resolve(uri);
              },
              "base64"
            );
          };
        };
        reader.readAsDataURL(fileOrUrl);
      }
    });

  const createPromotionQR = async () => {
    if (person.processedImage != "" && person.userId != "") {
      dispatch(setActiveStep(1));
      return;
    }
    const queryString = window.location.search;
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(queryString);
    // Get the value of a specific query parameter
    const acCode = queryParams.get("ac");

    const links = [
      person.personalLink1,
      person.personalLink2,
      person.personalLink3,
    ];
    const titles = [
      person.personalLink1Title,
      person.personalLink2Title,
      person.personalLink3Title,
    ];
    if (!links.some(Boolean)) {
      showModal("Notification", "At least one link must be provided.");
      return;
    }

    // Check for valid URLs and if a title is present without a link
    for (let i = 0; i < links.length; i++) {
      // if (links[i]) {
      //   showModal("Notification", `Link ${i+1} is not a valid HTTP or HTTPS link.`);
      //   return;
      // }
      if (links[i] && !titles[i]) {
        showModal(
          "Notification",
          `Link ${i + 1} has a link but its title is empty.`
        );
        return;
      }
    }

    dispatch(
      setPerson({
        loadingTitle: "Generating your promotional QR",
        isLoading: true,
      })
    );

    const formData = {
      qrType: "promotional",
      personalLink1:
        person.personalLink1 != "" && !person.personalLink1.startsWith("http")
          ? "https://" + person.personalLink1
          : person.personalLink1,
      personalLink1Title: person.personalLink1Title,
      personalLink2:
        person.personalLink2 != "" && !person.personalLink2.startsWith("http")
          ? "https://" + person.personalLink2
          : person.personalLink2,
      personalLink2Title: person.personalLink2Title,
      personalLink3:
        person.personalLink3 != "" && !person.personalLink3.startsWith("http")
          ? "https://" + person.personalLink3
          : person.personalLink3,
      personalLink3Title: person.personalLink3Title,
      acCode: acCode,
    };

    try {
      dispatch(
        setPerson({ loadingTitle: "Generating your QR", isLoading: true })
      );
      const response = await axiosApiInstance.post("/qr_preview", formData);
      dispatch(
        setPerson({
          processedImage: response.data.imageData,
          userId: response.data.userid,
          isLoading: false,
        })
      );
      window.scrollTo(0, 0);
      dispatch(setActiveStep(activeStep + 1));
    } catch (error) {
      console.log(error);
      dispatch(setLoading(false));
      showModal("Notification", error.response.data.message);
      console.error(error);
    }
  };

  const createPreviewQR = async () => {
    if (person.processedImage != "" && person.userId != "") {
      dispatch(setActiveStep(1));
      return;
    }
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const acCode = queryParams.get("ac");
    let userId = "";
    if (person.userId && person.userId != "") userId = person.userId;

    if (!person.firstName.trim()) {
      showModal("Notification", "Please enter your first name.");
      return;
    }

    if (!person.phoneMobile.trim()) {
      showModal("Notification", "Please enter your phone number.");
      return;
    }

    if (person.phoneMobile !== person.confirmPhoneMobile) {
      showModal("Notification", "Please confirm your Phone (Mobile).");
      return;
    }

    if (!person.email.trim()) {
      showModal("Notification", "Please enter your email.");
      return;
    }

    if (person.email !== person.confirmEmail) {
      showModal("Notification", "Please confirm your Email.");
      return;
    }
    console.log(person);

    const links = [
      person.personalLink1,
      person.personalLink2,
      person.personalLink3,
    ];
    const titles = [
      person.personalLink1Title,
      person.personalLink2Title,
      person.personalLink3Title,
    ];
    // if (!links.some(Boolean)) {
    //   showModal("Notification", "At least one link must be provided.");
    //   return;
    // }

    // // Check for valid URLs and if a title is present without a link
    // for (let i = 0; i < links.length; i++) {
    //   // if (links[i]) {
    //   //   showModal("Notification", `Link ${i+1} is not a valid HTTP or HTTPS link.`);
    //   //   return;
    //   // }
    //   if (links[i] && !titles[i]) {
    //     showModal("Notification", `Link ${i + 1} has a link but its title is empty.`);
    //     return;
    //   }
    // }

    const errors = validateEmailAndPhone(
      person.email,
      person.confirmEmail,
      person.phoneMobile,
      person.confirmPhoneMobile,
      person.phoneWork,
      person.confirmPhoneWork
    );

    if (
      errors.email != "" ||
      errors.confirmEmail != "" ||
      errors.phoneMobile != "" ||
      errors.confirmPhoneMobile != "" ||
      errors.phoneWork != "" ||
      errors.confirmPhoneWork != ""
    ) {
      showModal("Notification", "Please enter the correct value.");
      return;
    }

    dispatch(
      setPerson({ loadingTitle: "Generating your QR", isLoading: true })
    );

    let avatarImage = "";
    if (person.avatar) {
      const binaryData = await resizeImgFile(person.avatar);
      avatarImage = binaryData;
    }

    console.log(avatarImage);

    const formData = {
      qrType: "personalized",
      firstName: person.firstName,
      lastName: person.lastName,
      orgName: person.organization,
      positionWork: person.positionWork,
      phoneWork: person.phoneWork,
      phoneMobile: person.phoneMobile,
      email: person.email,
      website: person.website,
      street: person.street,
      zipCode: person.zipCode,
      city: person.city,
      naState: person.naState,
      country: person.country,
      acCode: acCode,
      personalLink1:
        person.personalLink1 != "" && !person.personalLink1.startsWith("http")
          ? "https://" + person.personalLink1
          : person.personalLink1,
      personalLink1Title: person.personalLink1Title,
      personalLink2:
        person.personalLink2 != "" && !person.personalLink2.startsWith("http")
          ? "https://" + person.personalLink2
          : person.personalLink2,
      personalLink2Title: person.personalLink2Title,
      personalLink3:
        person.personalLink3 != "" && !person.personalLink3.startsWith("http")
          ? "https://" + person.personalLink3
          : person.personalLink3,
      personalLink3Title: person.personalLink3Title,
      acCode: acCode,
      image: avatarImage,
      userId: userId,
    };

    try {
      dispatch(
        setPerson({ loadingTitle: "Generating your QR", isLoading: true })
      );
      const response = await axiosApiInstance.post("/qr_preview", formData);
      dispatch(
        setPerson({
          processedImage: response.data.imageData,
          userId: response.data.userid,
          isLoading: false,
          affiliateLink: response.data.affiliateURL,
        })
      );
      window.scrollTo(0, 0);
      dispatch(setActiveStep(activeStep + 1));
    } catch (error) {
      console.log(error);
      // setLoading (false);
      dispatch(setLoading(false));
      showModal("Notification", error.response.data.message);
      console.error("Error:", error);
    }
  };

  const handleStripeGateway = async (e) => {
    // setLoading (true);
    // setLoadingTitle ('Please wait...');
    dispatch(setPerson({ isLoading: true, loadingTitle: "Please wait..." }));
    await axiosApiInstance
      .post(
        "/create_payment_intent",
        {
          userId: person.userId,
          delta: person.selectedLocationOption,
          purchasePod: false,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        if (res.data.redirectUrl == null || res.data.redirectUrl == undefined) {
          showModal("Notification", res.data.message);
          // setLoading (false);
          dispatch(setLoading(false));
          return;
        }
        window.open(res.data.redirectUrl);
        // window.location.href = res.data.redirectUrl;
        // setLoading (false);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        // setLoading (false);
        dispatch(setLoading(false));
      });
  };

  // Update useEffect to add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const contactPreview = document.getElementById("contactPreview");
      const extraButtonWrapper = document.getElementsByClassName(
        "extra_button_wrapper"
      )[0];
      const qrPagePrev = document.getElementById("qr_page_prev");
      const scrollTop = window.scrollY; // Use window.scrollY to get the scroll position

      if (scrollTop >= 250) {
        contactPreview.style.top = "0"; // Change this to your specific value
        extraButtonWrapper.style.bottom = `calc(100vh - 680px)`;
      } else {
        contactPreview.style.top = `${250 - scrollTop}px`; // Original value or another value when scrolled less than 300
        extraButtonWrapper.style.bottom = `calc(100vh - ${925 - scrollTop}px)`;
      }

      const maxHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const maxQrPagePrevScroll =
        qrPagePrev.scrollHeight - qrPagePrev.clientHeight;

      // Calculate the new scroll position for qr_page_prev
      const newScrollPosition = (scrollTop / maxHeight) * maxQrPagePrevScroll;

      qrPagePrev.scrollTo({
        top: newScrollPosition,
        behavior: "smooth",
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      {person.isLoading ? (
        <LoadingDialog title={person.loadingTitle} />
      ) : (
        <div />
      )}
      <CssBaseline />
      <Container
        component="main"
        ref={mainContainerRef}
        sx={{ mb: 4, minHeight: "100vh" }}
        className="checkout"
      >
        <Grid
          container
          spacing={2}
          sx={{ display: { xs: "block", sm: "flex" } }}
        >
          <Grid
            item
            xs={12}
            sm={8}
            sx={{
              display:
                activeMobileView === "content" || !isMobileView
                  ? "block"
                  : "none",
              px: { xs: 1, sm: 2 },
            }}
          >
            <Paper
              variant="outlined"
              style={{ minHeight: "800px" }}
              sx={{ my: { xs: 3, md: 2 }, p: { xs: 2, md: 3 } }}
            >
              {/* <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper> */}
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* {activeStep == 2 &&
                    <Button
                      className="btn-checkout"
                      onClick={handleBack}
                      sx={{mt: 3, ml: 1}}
                    >
                      Back
                    </Button>} */}

                  <Button
                    variant="contained"
                    className="btn-checkout btn-submit"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                    style={{ marginTop: "50px" }}
                    hidden={!person.showAdditionalContent && activeStep === 1}
                  >
                    {/* {activeStep === steps.length - 1 ? 'Purchase' : 'Next'} */}
                    {getButtonText(activeStep)}
                  </Button>
                </Box>

                <BasicModal
                  open={modalOpen}
                  title={modalTitle}
                  content={modalContent}
                  showButton={showButton}
                  handleClose={() => {
                    setModalOpen(false);
                  }}
                  handleClick={() => {
                    setModalOpen(false);
                  }}
                />
              </React.Fragment>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            id="contactPreview"
            sx={{
              display:
                activeMobileView === "preview" || !isMobileView
                  ? "block"
                  : "none",
              position: { sm: "fixed" },
              top: { sm: "250px" },
              left: { sm: "calc(50% + 205px)" },
            }}
          >
            <ContactPreview />
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          position: "fixed",
          bottom: "0",
          width: "100%",
          backgroundColor: "#fff",
          zIndex: 1000,
          boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Button
          variant={activeMobileView === "content" ? "contained" : "outlined"}
          onClick={() => setActiveMobileView("content")}
          sx={{ width: "50%" }}
        >
          Content
        </Button>
        <Button
          variant={activeMobileView === "preview" ? "contained" : "outlined"}
          onClick={() => setActiveMobileView("preview")}
          sx={{ width: "50%" }}
        >
          Preview
        </Button>
      </Box>

      <Footer />
    </React.Fragment>
  );
}
