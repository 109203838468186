import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { colors } from "@mui/material";

function Footer({ color = "text.secondary", position = "relative" }) {
  return (
    <>
      <div
        className="footer"
        style={{
          position: position,
        }}
      >
        <Typography variant="body2" color={color} align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://www.ua.theqr.store/">
            The QR Store
          </Link>
          &nbsp;{new Date().getFullYear()}
        </Typography>
      </div>
    </>
  );
}

export default Footer;
