import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import axiosApiInstance from "../../store/axiosApi";
import LoadingDialog from "./modal";
import constants from "../../config/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function RedirectModal({ opend, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const handleSend = async () => {
    if (emailAddress == "") {
      setResponseMessage("Please input your email address.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axiosApiInstance.post("/redirect_link", {
        email: emailAddress,
        link: window.location.href,
      });
      // setInputModalShow (false);
      setEmailAddress("");
      setResponseMessage(response.data.message);
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setResponseMessage(constants.serverConnectionErrMsg);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal
        open={opend}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            If you want to view the site on your mobile, please continue.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If you want to view on your PC, please input your email address and
            click SEND button.
          </Typography>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="standard"
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          {responseMessage && <Alert severity="info">{responseMessage}</Alert>}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="btn-checkout"
              sx={{ mt: 3, ml: 1 }}
              variant="contained"
              color="success"
              onClick={handleClose}
            >
              Continue
            </Button>
            <div>
              <LoadingButton
                loading={isLoading}
                sx={{ mt: 3, ml: 1 }}
                variant="contained"
                onClick={() => {
                  handleSend();
                }}
              >
                Send
              </LoadingButton>
              <Button
                className="btn-checkout"
                sx={{ mt: 3, ml: 1 }}
                variant="contained"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
      {isLoading ? <LoadingDialog title="loading..." /> : <div />}
    </div>
  );
}
