import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BasicModal from "../BasicModal/modal";
import LoadingDialog from "../Loading";
import axiosApiInstance from "../../store/axiosApi";

export default function DashboardReview() {
  const [token, setToken] = useState("");
  const [paymentExist, setPaymentExist] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setToken(token);

      const response = await axiosApiInstance.post("/paymentMethod", {
        token: token,
      });
      if (response.data.success) {
        setLoading(false);
        setPaymentExist(response.data.exist);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <LoadingDialog /> : <div />}
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={"/img/logo-white.png"} />
          <BreadcrumbSection
            heading="AFFILIATE DASHBOARD"
            home="Home"
            page="DASHBOARD"
            title="DASHBOARD"
          />
          <Container component="main" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
            >
              <React.Fragment>
                <Box sx={{ bgcolor: "background.paper" }}>
                  <Box>
                    <div className="row">
                      <div className="row" bis_skin_checked="1">
                        <Link
                          to={{
                            pathname: "/getting_started",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-1 icon-bg-6 dashboard-icon">
                              <img src="/img/rocket.png" />
                            </span>

                            <h4 className="my-3">Getting Started</h4>
                            <p>
                              How to be an affiliate marketer for The QR Store{" "}
                            </p>
                          </div>
                        </Link>
                        <Link
                          to={{
                            pathname: "/reorder",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-1 icon-bg-3 dashboard-icon">
                              <img src="/img/qr-code.png" alt="QR Code" />
                            </span>
                            <h4 className="my-3">Reorder QR code tags</h4>
                            <p>Order additional engraved QR code tags</p>
                          </div>
                        </Link>
                        {/* <div
                          onClick={handleReorder}
                          className={`col-12 col-md-3 col-lg-3 review-container`}
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-1 icon-bg-3 dashboard-icon">
                              <img src="/img/qr-code.png" alt="QR Code" />
                            </span>
                            <h4 className="my-3">Reorder QR code tags</h4>
                            <p>Order additional engraved QR code tags</p>
                          </div>
                        </div> */}
                        <Link
                          to={{
                            pathname: "/downstream",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-1 icon-bg-4 dashboard-icon">
                              <img src="/img/calendar.png" />
                            </span>
                            <h4 className="my-3">Your Downstream Activity</h4>
                            <p>
                              You can see the number of affiliates at each
                              level.
                            </p>
                          </div>
                        </Link>

                        <Link
                          to={{
                            pathname: "/payment_history",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-5 icon-bg-5 dashboard-icon">
                              <img src="/img/cashless-payment.png" />
                            </span>
                            <h4 className="my-3">Your Affiliate Commissions</h4>
                            <p>View current balance and payment history</p>
                          </div>
                        </Link>
                        <Link
                          to={{
                            pathname: "/contact_info",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-2 icon-bg-2 dashboard-icon">
                              {" "}
                              <img src="/img/user.png" />
                            </span>
                            <h4 className="my-3">Your Contact Information</h4>
                            <p>Manage your Profile.</p>
                          </div>
                        </Link>
                        <Link
                          to={{
                            pathname: "/payment",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-2 icon-bg-3 dashboard-icon">
                              {" "}
                              <img src="/img/debit-card.png" />
                            </span>
                            <h4 className="my-3">Your Payment Method</h4>
                            <p>How you will receive your affiliate payments</p>
                            {!paymentExist && (
                              <p className="color-transition">
                                You haven't yet setup payment method!
                              </p>
                            )}
                          </div>
                        </Link>
                        <Link
                          to={{
                            pathname: "/calculator",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-2 icon-bg-3 dashboard-icon">
                              {" "}
                              <img src="/img/calculator.png" />
                            </span>
                            <h4 className="my-3">Affiliate Calculator</h4>
                            <p>
                              Calculate how much you could make as an affiliate
                            </p>
                          </div>
                        </Link>
                        <Link
                          to={{
                            pathname: "/subscription",
                            search: token != null ? `?token=${token}` : "",
                          }}
                          target="_self"
                          className="col-12 col-md-3 col-lg-3"
                          bis_skin_checked="1"
                        >
                          <div
                            className="single-service text-center p-4"
                            bis_skin_checked="1"
                          >
                            <span className="color-2 icon-bg-3 dashboard-icon">
                              {" "}
                              <img src="/img/subscription.png" />
                            </span>
                            <h4 className="my-3">Subscription Status</h4>
                            <p>Your subscription status.</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Box>
                </Box>
              </React.Fragment>
            </Paper>
          </Container>
          <ModalSearchSection />
          <ModalMenuSection />
          <BasicModal
            open={modalOpen}
            title={modalTitle}
            content={modalContent}
            showButton={false}
            handleClose={() => setModalOpen(false)}
            handleClick={() => setModalOpen(false)}
          />
          <Footer />
        </div>
      </div>
    </>
  );
}
