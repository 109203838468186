import React, { useState, useEffect, useCallback } from "react";
import ScrollupSection from "./Scrollup/Scrollup";
import Header from "./Header/HeaderOne";
import BreadcrumbSection from "./Breadcrumb/BreadcrumbThree";
import ModalMenuSection from "./Modal/ModalMenu";
import LoadingDialog from "./Loading";
import axiosApiInstance from "../store/axiosApi";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import constants from "../config/constants";
import Footer from "./Footer";

export default function StripeV5() {
  const [loading, setLoading] = useState(false);
  const [sbError, setSbError] = useState(false);

  const [storeId, setStoreId] = useState(null);
  const [productId, setProductId] = useState(null);

  const navigate = useNavigate();

  // Function to handle the navigation
  const goToHome = () => {
    navigate(`/`);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setSbError(false);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const session_id = queryParams.get("session_id");
      const purchase_id = queryParams.get("purchase_id");
      const store_id = queryParams.get("store_id");
      const product_id = queryParams.get("product_id");

      setStoreId(store_id);
      setProductId(product_id);

      const response = await axiosApiInstance.post("/verifyEZStore", {
        session_id: session_id,
        purchase_id,
        store_id,
        product_id
      });
      console.log(response);
      setLoading(false);
      if (response.status == 200) {
      } else {
        setSbError(true);
      }
    } catch (error) {
      setLoading(false);
      setSbError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />

        <BreadcrumbSection
          heading={"Purchase COMPLETED!"}
          home={"Home"}
          page={"Purchase Page"}
          title={"PURCHASE"}
        />

        <React.Fragment>
          <CssBaseline />
          <Container
            component="main"
            maxWidth="sm"
            sx={{ mb: 4 }}
            className="mainContainer"
          >
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <React.Fragment>
                {!sbError ? (
                  <div style={{ textAlign: "center" }}>
                    {!loading ? (
                      <div>
                        <h3 className="store-name">
                          {storeId == 'ezstore' ? "EZStore" : "Lisa Driscoll Art"}
                        </h3>
                        {productId == 'greenflash' ?
                          <img src="/img/EZStore-GreenFlashWith Signature.png" alt="The Green Flash" className="product-image" /> :
                          <img src="/img/Good Puppy.LisaDriscollArt.jpg" alt="Good Puppy" className="product-image" /> }
                        <h2 className="product-title">{productId == 'greenflash' ? "Elusive Green Flash - Digital Download" : "Good Puppy"}</h2>
                        <Button
                          variant="contained"
                          color="primary"
                          className="text-smallcase"
                          style={{
                            width: "200px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                          onClick={goToHome} // Set the click handler to your navigation function
                        >
                          Home
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <LoadingDialog title="Thanks for your purchase.....Processing your purchase." />
                      </div>
                    )}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p>{constants.qrGenerationErrMsg}</p>

                    <Button
                      onClick={() => fetchData()}
                      variant="contained"
                      style={{
                        width: "200px",
                        color: "white",
                        marginBottom: "15px",
                        marginTop: "15px",
                      }}
                      color="error"
                    >
                      Refresh
                    </Button>
                  </div>
                )}
              </React.Fragment>
            </Paper>
          </Container>
        </React.Fragment>

        <ModalMenuSection />
        <Footer />
      </div>
    </div>
  );
}
