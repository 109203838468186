import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Link,
  SvgIcon,
  IconButton,
} from "@mui/material";
import {
  AddSharp,
  ArrowForwardIos,
  Directions,
  LinkSharp,
  Mail,
  NearMeSharp,
  Smartphone,
  Sms,
} from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { QrCode2Sharp, ShortcutSharp } from "@mui/icons-material";

import axiosApiInstance from "../../store/axiosApi";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const galleryImages = [
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_1.png",
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_2.png",
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_1.png",
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_2.png",
];

export default function LandingPagePreview() {
  const [isLoading, setIsLoading] = useState(true);
  const [isQrcodePopupOpen, setQrcodePopupOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState("");
  const [qrData, setQRData] = useState("");
  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneWork, setPhoneWork] = useState("");
  const [email, setEmail] = useState("");
  const [posWork, setPosWork] = useState("");
  const [address, setAddress] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [affiliateLink, setAffiliateLink] = useState("");
  const [website, setWebsite] = useState([]);
  const [websiteTitle, setWebsiteTitle] = useState([]);
  const [isPurchased, setIsPurchased] = useState(false);
  const { session_id = "971afcee" } = useParams();

  const isNonStandardPort =
    window.location.port && !["80", "443"].includes(window.location.port);
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    isNonStandardPort ? `:${window.location.port}` : ""
  }`;

  useEffect(() => {
    setName("John Doe");
    setEmail("john.doe@example.com");
    setOrgName("Example Organization");
    setPhone("123-456-7890");
    setPhoneWork("098-765-4321");
    setPosWork("Software Engineer");
    setAddress("123 Main St, Anytown, CA 12345");
    setStreet("123 Main St");
    setCity("Anytown");
    setState("CA");
    setZipcode("12345");
    setCountry("United States");
    setAffiliateLink(`${baseUrl}affiliate_link/971afcee`);
    setWebsite([
      "https://example.com",
      "https://facebook.com/johndoe",
      "https://instagram.com/johndoe",
    ]);
    setWebsiteTitle(["Website", "Facebook", "Instagram"]);
    setPreview("/img/john_doe.png");

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Replace 'http://example.com/orders' with your actual API endpoint
      const response = await axiosApiInstance.post(`/getDynamicQR`, {
        session_id,
      });
      if (response.data.success) {
        setIsLoading(false);
        console.log(response.data.user);
        setPreview(response.data.user.preview);
        setQRData(response.data.user.qrData);
        setName(response.data.user.full_name);
        setEmail(response.data.user.email);
        setOrgName(response.data.user.orig_name);
        setPhone(response.data.user.phone_mobile);
        setPhoneWork(response.data.user.phone_work);
        setPosWork(response.data.user.position_work);
        setStreet(response.data.user.street);
        setCity(response.data.user.city);
        setState(response.data.user.state);
        setZipcode(response.data.user.zipcode);
        setCountry(response.data.user.country);
        console.log(response.data.user.orig_name, posWork);

        if (!street.trim() && !city.trim() && !state.trim() && !zipcode.trim())
          setAddress(`${street}, ${city}, ${state} ${zipcode}`);
        setAffiliateLink(
          `${baseUrl}/affiliate_link/${response.data.user.session_id}`
        );
        setWebsite([
          response.data.user.link1,
          response.data.user.link2,
          response.data.user.link3,
        ]);
        setWebsiteTitle([
          response.data.user.link1_title,
          response.data.user.link2_title,
          response.data.user.link3_title,
        ]);
        setIsPurchased(response.data.user.is_purchased === 1);
      }
    } catch (error) {
      // Handle the error here. Could be a simple console log or error message display.
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    // if (selectedFile == null) {
    //   alert("Select Image.");
    //   return;
    // }
    setIsLoading(true);
    try {
      let binaryData = preview;
      if (selectedFile != null) {
        binaryData = await resizeImgFile(selectedFile);
        console.log(binaryData);
      }

      const response = await axiosApiInstance.post("/generateVCF", {
        imageContent: binaryData,
        session_id: session_id,
      });

      const vCardData = response.data;
      const blob = new Blob([vCardData], { type: "text/vcard" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${name}.vcf`;
      link.click();
      document.body.removeChild(link);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files[0] == undefined) return;
    setSelectedFile(event.target.files[0]);
  };

  // useEffect(() => {
  //   if (!selectedFile) {
  //     setPreview("https://via.placeholder.com/150");
  //     return;
  //   }

  //   const objectURL = window.URL.createObjectURL(selectedFile);
  //   setPreview(objectURL);
  //   console.log(objectURL);
  //   return () => window.URL.revokeObjectURL(objectURL);
  // }, [selectedFile]);

  const resizeImgFile = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          let newWidth;
          let newHeight;

          if (width > height) {
            newWidth = 350;
            newHeight = undefined;
          } else {
            newWidth = undefined;
            newHeight = 350;
          }

          Resizer.imageFileResizer(
            file,
            newWidth,
            newHeight,
            "JPEG",
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            "base64"
          );
        };
      };
      reader.readAsDataURL(file);
    });

  const handleQRCodeButtonClick = () => {
    setQrcodePopupOpen(true);
  };

  const handleQRCodePopupClose = () => {
    setQrcodePopupOpen(false);
  };

  const iconStyle = {
    backgroundColor: "#333", // Adjust the color to match your style
    color: "#fff",
    borderRadius: "50%",
    margin: "0 10px",
  };

  return (
    <>
      <Card sx={{ p: 2 }} className="preview_wrapper">
        <Box className="landing_page_preview_frame">
          <Box className="device-header"></Box>
          {isLoading ? (
            <Box className="qr_page_loader">
              <img className="loader_img" src="/qrlogo.gif" alt="Loading..." />
            </Box>
          ) : (
            <Box
              id="qr_page_prev"
              sx={{
                zoom: 1,
                overflowX: "hidden",
                overflowY: "scroll",
                position: "relative",
                padding: 0,
              }}
              className="qr_page_preview qrp_prev_wrapper thinScrollBar"
            >
              {/* <Iframe 
              url="data:text/html;charset=utf-8,<html>...</html>" 
              className="landing_page_iframe" 
              width="100%" 
              height="798px"
            /> */}
              <Box className="page_wrapper">
                <Box className="qrc_page_wrapper thinScrollBar">
                  <Box
                    className="pg_background"
                    sx={{ backgroundImage: "url('')" }}
                  ></Box>
                  <Box className="qrc_page_inner">
                    {/* Profile Section */}
                    <Box className="section qrc_profile_3">
                      <Box className="qrc_profile_inner">
                        <Box
                          className="qrc_profilepic"
                          sx={{
                            backgroundPosition: "top center",
                            backgroundImage: `url(\'${
                              preview || "/img/john_doe.png"
                            }\')`,
                          }}
                        >
                          <svg
                            id="Layer_1"
                            className="qrc_profile_3_svg"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 375 260"
                          >
                            <defs>
                              <style>{`
                            .cls-1 {
                              fill: url(#linear-gradient);
                            }
                          `}</style>
                              <linearGradient
                                id="linear-gradient"
                                x1="1.06"
                                y1="260.32"
                                x2="1.06"
                                y2="259.32"
                                gradientTransform="translate(-208.5 67424) scale(375 -259)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop
                                  offset="0"
                                  stopColor="#000"
                                  stopOpacity="0"
                                  style={{ stopColor: "var(--qrc-secondary)" }}
                                />
                                <stop
                                  offset="1"
                                  stopColor="#000"
                                  style={{ stopColor: "var(--qrc-secondary)" }}
                                />
                              </linearGradient>
                            </defs>
                            <rect
                              id="Rectangle_297"
                              data-name="Rectangle 297"
                              className="cls-1"
                              width="375"
                              height="260"
                            />
                          </svg>
                        </Box>
                        <Box className="qrc_profile_inner_info">
                          <Typography variant="h2">{name}</Typography>
                          {/* <Typography variant="body2">{posWork}</Typography>
                          <Typography variant="body2">
                            <strong>{orgName}</strong>
                          </Typography> */}
                          {/* <Box className="qrc_profile_brand_logo">
                        <img src="qrlogo.png" alt="Brand Logo"/>
                      </Box> */}
                          {/* <Box className="qrc_profile_shortcut">
                            <ul>
                              <li className="qr_cc_card">
                                <Link href={`tel:${phone}`}>
                                  <IconButton style={iconStyle}>
                                    <Smartphone />
                                  </IconButton>
                                </Link>
                              </li>
                              <li className="qr_cc_card">
                                <Link href={`mailto:${email}`}>
                                  <IconButton style={iconStyle}>
                                    <Mail />
                                  </IconButton>
                                </Link>
                              </li>
                              <li className="qr_cc_card">
                                <Link href={`sms:${phone}`}>
                                  <IconButton style={iconStyle}>
                                    <Sms />
                                  </IconButton>
                                </Link>
                              </li>
                            </ul>
                          </Box> */}
                        </Box>
                      </Box>
                    </Box>

                    {/* About Me Section */}
                    {(orgName || posWork) && (
                      <Box className="section qrc_heading_text qr_cc_card">
                        <Typography variant="h2">{orgName}</Typography>
                        <Typography variant="body2">{posWork}</Typography>
                      </Box>
                    )}

                    {/* Contact Section */}
                    <Box className="section qrc_contact qr_cc_card">
                      <Box className="qrc_contact_header">
                        <IconButton style={iconStyle}>
                          <Smartphone />
                        </IconButton>
                        <Box className="qrc_contact_hdr_text">Contact</Box>
                      </Box>
                      <Box className="qrc_contact_info">
                        <div className="qrc_contact_info_title">
                          Phone (Mobile)
                        </div>
                        <a href={`tel:${phone}`} className="qrc_contact_number">
                          {phone}
                        </a>
                      </Box>
                      <Box className="qrc_contact_info">
                        <div className="qrc_contact_info_title">
                          Phone (Work)
                        </div>
                        <a
                          href={`tel:${phoneWork}`}
                          className="qrc_contact_number"
                        >
                          {phoneWork}
                        </a>
                      </Box>
                      <Box className="qrc_email_info">
                        <div className="qrc_email_info_title">Email</div>
                        <a href={`mailto:${email}`} className="qrc_email_id">
                          {email}
                        </a>
                      </Box>
                      <Box className="qrc_address_info">
                        <div className="qrc_address_info_title">Address</div>
                        {(street || city || state || zipcode || country) && (
                          <>
                            <div className="qrc_address_text">
                              {street}
                              <br />
                              {city}, {state} {zipcode}
                              <br />
                              {country}
                            </div>
                            <a
                              className="qrc_direction_btn"
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                street +
                                  ", " +
                                  city +
                                  ", " +
                                  state +
                                  " " +
                                  zipcode +
                                  ", " +
                                  country
                              )}`}
                              target="_blank"
                            >
                              <NearMeSharp
                                style={{ color: "#fff", padding: "4px" }}
                              />
                              <span>Direction</span>
                            </a>
                          </>
                        )}
                        {/* <div className="qrc_address_text">{address}</div> */}
                      </Box>
                    </Box>

                    {/* Social Links Section */}
                    <Box className="section qrc_social_links">
                      <div className="qrc_heading">
                        <Typography variant="h2">Web Links</Typography>
                        {/* <Typography variant="body2">Description</Typography> */}
                      </div>
                      <ul className="qrc_social_links_list">
                        {website.map(
                          (link, index) =>
                            link &&
                            websiteTitle[index] && (
                              <li key={index} className="qrc_social_link">
                                <Link href={link} target="_blank">
                                  <Box className="qrc_social_icon">
                                    <IconButton>
                                      <LinkSharp />
                                    </IconButton>
                                  </Box>
                                  <Box className="qrc_social_text">
                                    <Typography variant="body1">
                                      {websiteTitle[index]}
                                    </Typography>
                                    <Typography variant="body2">
                                      {link}
                                    </Typography>
                                  </Box>
                                  <Box className="qrc_social_action">
                                    <IconButton>
                                      <ArrowForwardIos />
                                    </IconButton>
                                  </Box>
                                </Link>
                              </li>
                            )
                        )}

                        <li className="qrc_social_link">
                          <Link href={affiliateLink} target="_blank">
                            <Box className="qrc_social_icon">
                              <IconButton>
                                <LinkSharp />
                              </IconButton>
                            </Box>
                            <Box className="qrc_social_text">
                              <Typography variant="body1">
                                Affiliate Link
                              </Typography>
                              <Typography variant="body2">
                                {affiliateLink}
                              </Typography>
                            </Box>
                            <Box className="qrc_social_action">
                              <IconButton>
                                <ArrowForwardIos />
                              </IconButton>
                            </Box>
                          </Link>
                        </li>

                        <li className="qrc_social_link">
                          <Link
                            href={`sms:?&body=Check out my contact link: ${baseUrl}/mycontact_page/${session_id}`}
                            target="_blank"
                          >
                            <Box className="qrc_social_icon">
                              <IconButton>
                                <Sms />
                              </IconButton>
                            </Box>
                            <Box className="qrc_social_text">
                              <Typography variant="body1">
                                Share your contact page
                              </Typography>
                              <Typography variant="body2">
                                {`${baseUrl}/mycontact_page/${session_id}`}
                              </Typography>
                            </Box>
                            <Box className="qrc_social_action">
                              <IconButton>
                                <ArrowForwardIos />
                              </IconButton>
                            </Box>
                          </Link>
                        </li>
                      </ul>
                    </Box>

                    {/* Social Links Section */}
                    <Box className="section qrc_caption">
                      <Typography variant="h6">
                        <strong>The QR Store</strong>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Extra Buttons */}
              {!isLoading && (
                <Box
                  className="extra_button_wrapper"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <IconButton
                      style={{ ...iconStyle, margin: "0px 5px" }}
                      className={`${!qrData && "qrc_addtocontact_disabled"}`}
                      onClick={() => {
                        if (qrData) handleQRCodeButtonClick();
                      }}
                    >
                      <QrCode2Sharp />
                    </IconButton>
                  </div>
                  <div
                    className={`qrc_addtocontact ${
                      !isPurchased && "qrc_addtocontact_disabled"
                    }`}
                    onClick={() => {
                      if (isPurchased) handleClick();
                    }}
                  >
                    <Box className="qrc_addtocontact_text">Add to Contact</Box>
                    <Box className="qrc_addtocontact_circle">
                      <AddSharp />
                    </Box>
                  </div>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Card>
      <Dialog
        open={isQrcodePopupOpen}
        onClose={handleQRCodePopupClose}
        aria-labelledby="qr-dialog-title"
        PaperProps={{
          className: "qr-dialog",
        }}
      >
        <DialogTitle id="qr-dialog-title">
          QR Code
          <IconButton
            aria-label="close"
            onClick={handleQRCodePopupClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {(() => {
            const base64Image = `data:image/png;base64,${qrData}`;
            return (
              <img src={base64Image} alt="QR Code" style={{ width: "280px" }} />
            );
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQRCodePopupClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `data:image/png;base64,${qrData}`;
              link.download = "QR_Code.png";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            color="primary"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
