import React, { Component, useState } from "react";
import Badge from "@mui/material/Badge";

class HeaderOne extends Component {
  render() {
    return (
      <header id="header">
        {/* Navbar */}
        <nav
          data-aos="zoom-out"
          data-aos-delay={800}
          className="navbar navbar-expand aos-init aos-animate"
        >
          <div className="container header">
            <div className="ml-auto" />
            {/* Navbar */}
            <ul className="navbar-nav items">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a href="/affiliate_program" className="nav-link">
                  Affiliate Program
                </a>
              </li>
              <li className="nav-item">
                <a href="/faq" className="nav-link">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a href="/ezstore" className="nav-link">
                  EZStore
                </a>
              </li>

              {/* <li className="nav-item">
                <a href="/contact" className="nav-link">Contact</a>
              </li> */}
            </ul>
            <ul className="navbar-nav toggle">
              <li className="nav-item">
                <a
                  href="/#"
                  className="nav-link"
                  data-toggle="modal"
                  data-target="#menu"
                >
                  <i className="fas fa-bars toggle-icon m-0" />
                </a>
              </li>
            </ul>
            {/* Navbar Action Button */}
            <ul className="navbar-nav action">
              <li className="nav-item ml-3">
                <a
                  href="/contact"
                  className="btn ml-lg-auto btn-bordered-white"
                >
                  <i className="fas fa-paper-plane contact-icon mr-md-2" />
                  Contact Us
                </a>
              </li>
              <li className="nav-item ml-3 version-link">
                <Badge badgeContent="3.0" color="primary"></Badge>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderOne;
