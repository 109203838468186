import React, {Component} from 'react';

import ScrollupSection from '../../Scrollup/Scrollup';
import Header from '../../Header/HeaderOne';
import BreadcrumbSection from '../../Breadcrumb/BreadcrumbOne';
import ContactSection from '../../Contact/ContactOne';
// import MapSection from '../../GoogleMap/Map';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ModalSearchSection from '../../Modal/ModalSearch';
import ModalMenuSection from '../../Modal/ModalMenu';
import Footer from "../../Footer";

class Contact extends Component {
  render () {
    return (
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={'/img/logo-white.png'} />
          <BreadcrumbSection
            heading={'Contact Us'}
            home={'Home'}
            page={'Pages'}
            title={'CONTACT'}
          />
          <ContactSection />
          {/* <MapSection /> */}
          {/* <FooterSection /> */}
          <ModalSearchSection />
          <ModalMenuSection />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Contact;
