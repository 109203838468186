import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import AffiliateLogin from "../BasicModal/AffiliateLogin";
import RedirectModal from "../BasicModal/RedirectModal";
import Footer from "../Footer";

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

function Landing({ acCode }) {
  const _initData = {
    heading: "THE",
    heading_span: `QR STORE`,
    btnText: "Affiliate Login",
    iconClass: "fas fa-play",
    image: "/img/hero_shape.png",
  };

  const _data = [
    {
      id: 1,
      spanClass: "welcome-thumb-1",
      imgClass: "welcome-animation d-block ml-auto",
      image: "/img/AffiliateMarkting.webp",
    },
  ];

  const [initData, setInitData] = useState(_initData);
  const [data, setData] = useState(_data);
  const [isLoading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [inputModalShow, setInputModalShow] = useState(false);
  const [redirectModalShow, setRedirectModalShow] = useState(isMobileDevice());
  const [modalStr, setModalStr] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [mobileNav, setMobileNav] = useState(false);

  return (
    <>
      <section
        id="home"
        className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center"
      >
        <div className="container">
          <div className="row align-items-center">
            {/* Welcome Intro Start */}
            <div className="col-12 col-md-6">
              <div className="welcome-intro">
                <h1 className="text-white text-center text-md-left">
                  {initData.heading}

                  <br />

                  <span className="fw-4">{initData.heading_span}</span>
                </h1>
                <p className="description text-white text-center text-md-left my-4">
                  No business cards with you? Need a quick and easy way to share
                  your contact information with a new acquaintance? With your
                  personalized contact QR code on the back of your phone,
                  clients and friends can simply scan it with their phone to
                  instantly add you to their contacts.
                  <br />
                  <br />
                  Not only does your personalized QR code replace business cards
                  and offer a convenient way to share your contact details with
                  friends, &nbsp;
                  <b className="affiliate-program-bold">
                    it can also generate income
                  </b>
                  ! Visit our
                  <a
                    href="/affiliate_program"
                    target="_blank"
                    className="affiliate-program-link"
                  >
                    <b className="affiliate-program-bold">Affiliate Program</b>
                  </a>
                  page for more information on leveraging your QR code as a
                  potentially &nbsp;
                  <b className="affiliate-program-bold">
                    lucrative side hustle
                  </b>
                  .
                </p>
                {/* Buttons */}
                <div className="button-group d-flex align-items-center justify-content-center justify-content-md-start">
                  <Link
                    to={{
                      pathname: "/purchase",
                      search: acCode != null ? `?ac=${acCode}` : "",
                    }}
                    className="btn btn-bordered-white"
                    onClick={() => localStorage.removeItem("personData")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-qr-code"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2h2v2H2V2Z" />
                      <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                      <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                      <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                      <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                    </svg>
                    Create Your QR
                  </Link>
                  <a
                    onClick={() => {
                      setInputModalShow(true);
                    }}
                    className="btn btn-bordered-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      style={{ fill: "none" }}
                      className="bi bi-currency-dollar"
                    >
                      <path
                        d="M18 8.5V8.35417C18 6.50171 16.4983 5 14.6458 5H9.5C7.567 5 6 6.567 6 8.5C6 10.433 7.567 12 9.5 12H14.5C16.433 12 18 13.567 18 15.5C18 17.433 16.433 19 14.5 19H9.42708C7.53436 19 6 17.4656 6 15.5729V15.5M12 3V21"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {initData.btnText}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* Welcome Thumb */}
              <div className="welcome-thumb-wrapper pt-3 pt-md-5 mt-5 welcome-page-logo">
                {data.map((item, idx) => {
                  return (
                    <span key={`hth_${idx}`} className={item.spanClass}>
                      <img className={item.imgClass} src={item.image} alt="" />
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <Footer color="white" position="fixed" />
        </div>
        {/* Shape Bottom */}
        <div className="welcome-shape">
          <img src={initData.image} alt="" />
        </div>
        <AffiliateLogin
          opend={inputModalShow}
          handleClose={() => {
            console.log("CLOSE");
            setInputModalShow(false);
          }}
        />
        <RedirectModal
          opend={redirectModalShow}
          handleClose={() => {
            console.log("CLOSE");
            setRedirectModalShow(false);
          }}
        />
      </section>
    </>
  );
}

export default Landing;
