import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import axiosApiInstance from "../../store/axiosApi";
import Popover from "@mui/material/Popover";
import Pagination from "@mui/material/Pagination";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import LoadingDialog from "../Loading";
import constants from "../../config/constants";
import BasicModal from "../BasicModal/modal";
import PaymentModal from "../BasicModal/PaymentModal";
import Footer from "../Footer";

export default function DashboardPaymentHistory() {
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [currentPaymentsPage, setCurrentPaymentPage] = useState(1);
  const [totalPaymentPages, setTotalPaymentPages] = useState(0);
  const [totalPaymentCounts, setTotalPaymentCounts] = useState(0);
  const [payments, setPayments] = useState([]);

  const [stripeRedirect, setStripeRedirect] = useState(false);
  const [earnedMoney, setEarnedMoney] = useState(0);
  const [fundEmail, setFundEmail] = useState("");
  const [fundMethod, setFundMethod] = useState(-1);

  const [connectUrl, setConnectUrl] = useState("");
  const [selectedPopId, setSelectedPopId] = useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [modalFundOpen, setModalFundOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalCounts, setTotalCounts] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [level, setLevel] = useState();
  const [query, setQuery] = useState("");
  const [token, setToken] = useState("");

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const queryString = window.location.search;
    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(queryString);
    // Get the value of a specific query parameter
    const token = queryParams.get("token");
    setToken(token);

    const stripe_redirect = queryParams.get("stripe_redirect");
    setStripeRedirect(stripe_redirect);
    let whichLevel = queryParams.get("level");
    if (whichLevel == null) whichLevel = 2;
    setLevel(whichLevel);
    setQuery(token);
  }, []);

  useEffect(() => {
    fetchPaymentsData();
  }, [currentPaymentsPage]);

  const handleStripeClick = async () => {
    try {
      setModalFundOpen(false);
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setLoading(true);
      await axiosApiInstance.post("/getMoney", {
        // await axiosApiInstance.post("/api/getMoney", {
        token: token,
      });
      setEarnedMoney(0);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.msg);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  const fetchPaymentsData = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setToken(token);

      const response = await axiosApiInstance.post("/dashboard_payments", {
        // const response = await axiosApiInstance.post("/api/dashboard/payments", {
        token: token,
        page: currentPaymentsPage,
      });
      if (response.data.success) {
        setLoading(false);
        setPayments(response.data.payments);
        setCurrentPaymentPage(response.data.currentPage);
        setTotalPaymentPages(response.data.total_pages);
        setTotalPaymentCounts(response.data.total_counts);
        setEarnedMoney(response.data.earnedMoney);
        setFundEmail(response.data.fund_email);
        setFundMethod(response.data.fund_method);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.msg);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"AFFILIATE DASHBOARD"}
          home={"Home"}
          page={"Dashboard"}
          title={"View Payment History"}
          pageLink={`/dashboard?token=${token}`}
          showBreadcrumb="true"
        />
        <Container component="main" sx={{ mb: 4 }} className="mainContainer">
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
          >
            <React.Fragment>
              <Box sx={{ bgcolor: "background.paper" }}>
                <h3 style={{ textAlign: "center" }}>View Payment History</h3>

                <Typography
                  variant="body1"
                  gutterBottom
                  style={{
                    textAlign: "center",
                    paddingTop: "15px",
                  }}
                >
                  Affiliate commissions will be paid the first week of each
                  month for any outstanding balace greater than $10.
                </Typography>
                <div>
                  <div className="payicon-area ">
                    <div>
                      {stripeRedirect != null ? (
                        stripeRedirect === "success" ? (
                          <p>You have received ${earnedMoney} successfully.</p>
                        ) : (
                          <p>Something went wrong during sending money.</p>
                        )
                      ) : (
                        // ) : earnedMoney > 0 ? (
                        //   <h5 href="#" onClick={() => setModalFundOpen(true)}>
                        //     <span style={{ cursor: "pointer", color: "#1599e5" }}>
                        //       Click here to receive ${earnedMoney}
                        //     </span>
                        //   </h5>
                        // ) : (
                        //   <p>You have no funds to receive.</p>
                        // )}
                        <h5 href="#" onClick={() => setModalFundOpen(true)}>
                          <span style={{ cursor: "pointer", color: "#50db55" }}>
                            Current balance due: ${earnedMoney}
                          </span>
                        </h5>
                      )}
                    </div>
                    <table className="table" style={{ marginTop: "25px" }}>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Date Paid</th>
                          <th scope="col">Amount($)</th>
                        </tr>
                      </thead>
                      {payments.length > 0 ? (
                        <tbody>
                          {payments.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.paid_date != null
                                  ? item.paid_date.substring(0, 10)
                                  : ""}
                              </td>
                              <td>{item.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No payments history.</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </table>

                    {totalPaymentPages != 0 ? (
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Pagination
                          onChange={(event, page) => {
                            if (currentPaymentsPage != page) {
                              setCurrentPaymentPage(page);
                            }
                          }}
                          count={totalPaymentPages}
                          color="primary"
                        />
                      </Box>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>

                <Popover
                  id={selectedPopId}
                  open={popOpen}
                  anchorEl={anchorEl}
                  onClose={() => {
                    setPopOpen(false);
                    setAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <CardContent>
                    <Typography>Name:{selectedUser?.full_name}</Typography>
                    <Typography>
                      Address:{selectedUser?.street},{selectedUser?.city},{" "}
                      {selectedUser?.state} {selectedUser?.zipcode},{" "}
                      {selectedUser?.country}
                    </Typography>
                    <Typography>Email: {selectedUser?.email}</Typography>
                    <Typography>
                      Phone: {selectedUser?.phone_mobile}{" "}
                      {selectedUser?.phone_work}
                    </Typography>
                  </CardContent>
                </Popover>
              </Box>
              {loading ? <LoadingDialog title="loading..." /> : <div />}
            </React.Fragment>
          </Paper>
          <BasicModal
            open={modalOpen}
            title={modalTitle}
            content={modalContent}
            showButton={false}
            handleClose={() => {
              setModalOpen(false);
            }}
            handleClick={() => {
              setModalOpen(false);
            }}
          />
          <PaymentModal
            open={modalFundOpen}
            email={fundEmail}
            fund_method={fundMethod}
            amount={earnedMoney}
            showButton={true}
            handleClose={() => {
              setModalFundOpen(false);
            }}
            handleClick={handleStripeClick}
          />
        </Container>
        <ModalSearchSection />
        <ModalMenuSection />
        <Footer />
      </div>
    </div>
  );
}
