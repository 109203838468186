import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PaymentModal({
  open,
  email,
  fund_method,
  amount,
  showButton,
  handleClose,
  handleClick = null,
}) {
  const calculatePaypalFee = (value) => {
    const fee = (value * 2.89) / 100;
    if (fee < 0.25) return 0.25;
    else if (fee > 25) return 25;
    else return fee.toFixed(2);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="basic-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you going to be paid money?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b>{`$${amount}`}</b> will be paid via your
            <b>{fund_method == 0 ? ` PayPal ` : ` Venmo `}</b>account
            <b style={{ color: "blue" }}>{` (${email}).`}</b>
          </Typography>
          <br />
          <div>
            <table style={{ textAlign: "right" }}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Affiliate Payment</td>
                  <td>${amount}</td>
                </tr>
                <tr>
                  <td>
                    <Tooltip
                      title="We use PayPal to send your money to either your PayPal account or your Venmo account. This is the fee PayPal changes for this transaction. They charge 2.89% with a minimum charge of $0.25."
                      placement="bottom"
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                marginTop: "0px",
                                fontSize: "18px",
                              },
                          },
                        },
                      }}
                    >
                      <span
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Transaction Fee
                      </span>
                    </Tooltip>
                  </td>
                  <td>-${calculatePaypalFee(amount)} </td>
                </tr>
                <tr>
                  <td></td>
                  <td>_ _ _ _ _ _</td>
                </tr>
                <tr>
                  <td>Total Transferred</td>
                  <td>${amount - calculatePaypalFee(amount)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {showButton && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-checkout"
                variant="contained"
                onClick={handleClick}
                sx={{ mt: 3, ml: 1 }}
              >
                Yes
              </Button>
              <Button
                className="btn-checkout"
                variant="contained"
                color="grey"
                onClick={handleClose}
                sx={{ mt: 3, ml: 1 }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
