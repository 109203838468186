import React from "react";

import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import Header from "../Header/HeaderOne";
import ScrollupSection from "../Scrollup/Scrollup";
import Footer from "../Footer";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";

import { ArrowForwardIosSharp } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import {Container, CssBaseline, Grid} from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    fontSize: '1.2rem', // Increase the font size
    fontWeight: 'bold',  // Make the font bold
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faq() {

  const faqData = [
    {
      question: "What type of QR code is this?",
      answer: "This is a Dynamic QR code. When scanned, it directs users to a website where they can easily add your contact information to their phone's Contacts. This is also called a digital business card."
    },
    {
      question: "What's a Dynamic QR code?",
      answer: "A Dynamic QR code typically contains a website link (URL) that can lead to a website, a restaurant menu, etc or in our case, a special \"mini-website\" hosted on our server, designed specifically for storing contact information. This hosting allows you to update your contact information without needing to change or reprint your QR code. In contrast, static QR codes offer limited content, such as text without pictures, and cannot be changed without reprinting and redistributing the QR code."
    },
    {
      question: "Can any smartphone scan my QR code?",
      answer: "Most recent smartphones can scan QR codes directly using their camera app. Simply open the camera app, ensure it's in photo mode, and point it at the QR code. You may need to adjust the distance or lighting for a successful scan. Once scanned, a website link will appear on your camera screen. Some older phones might require a dedicated QR code scanning app and others may have a camera setting to enable QR code scanning."
    },
    {
      question: "Does The QR Store have an Affiliate Marketing Program?",
      answer: "Yes, by purchasing a QR code, you're automatically enrolled in our Affiliate Marketing Program. When someone scans your QR code and adds your contact information, they'll see your Affiliate Link. Clicking this link allows them to purchase their own QR code, and you'll earn a commission for any purchases made through your link. You can manage your affiliate information and edit your contact details through your Affiliate Dashboard."
    }
  ];

  const [expanded, setExpanded] = React.useState('panel0');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"FAQ"}
          home={"Home"}
          page={"Pages"}
          title={"CONTACT"}
        />
        <React.Fragment>
          <CssBaseline />
          <Container
            component="main"
            sx={{ mb: 4 }}
            className="mainContainer"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} sx={{alignContent: 'center'}}>
                <img
                  src="img/faq_bg.png" // Replace with the actual path to your image
                  alt="FAQ background"
                  className="faq-image"
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{alignContent: 'center'}}>
                {faqData.map((faq, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index}d-content`}
                      id={`panel${index}d-header`}
                    >
                      <Typography>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Container>
        </React.Fragment>
        <ModalSearchSection />
        <ModalMenuSection />
        <Footer />
      </div>
    </div>
  );
}