const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export default function validateEmailAndPhone(
  email,
  confirmEmail,
  phoneMobile,
  confirmPhoneMobile,
  phoneWork,
  confirmPhoneWork
) {
  let tempErrors = {
    email: "",
    confirmEmail: "",
    phoneMobile: "",
    confirmPhoneMobile: "",
    phoneWork: "",
    confirmPhoneWork: "",
  };
  tempErrors.email = emailPattern.test(email) ? "" : "Email is not valid.";
  tempErrors.confirmEmail =
    email === confirmEmail ? "" : "Emails do not match.";
  tempErrors.phoneMobile = phonePattern.test(phoneMobile)
    ? ""
    : "Phone (Mobile) number is not valid.";
  tempErrors.confirmPhoneMobile =
    phoneMobile === confirmPhoneMobile
      ? ""
      : "Phone (Mobile) numbers do not match.";
  if (phoneWork != "")
    tempErrors.phoneWork = phonePattern.test(phoneWork)
      ? ""
      : "Phone (Work) number is not valid.";
  tempErrors.confirmPhoneWork =
    phoneWork === confirmPhoneWork ? "" : "Phone (Work) numbers do not match.";

  return tempErrors;
  // Form is valid if there are no error messages
  //   return !Object.values (tempErrors).some (error => error !== '');
}
