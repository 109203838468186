import React from 'react';
const LoadingDialog = ({title}) => {
  return (
    <div className="loading-dialog">
      <div className="spinner-border text-light" role="status">
        <span className="sr-only" />
      </div>
      <span style={{marginLeft: '15px', color: 'white'}}>{title}</span>
    </div>
  );
};

export default LoadingDialog;
