import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import LoadingDialog from "../Loading";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";

export default function GettingStarted() {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setToken(token);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.message);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  return (
    <div>
      {loading ? <LoadingDialog /> : <div />}
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={"/img/logo-white.png"} />
          <BreadcrumbSection
            heading={"AFFILIATE DASHBOARD"}
            home={"Home"}
            page={"Dashboard"}
            title={"Getting Started"}
            pageLink={`/dashboard?token=${token}`}
            showBreadcrumb="true"
          />
          <Container component="main" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
            >
              <div class="content">
                <h2>Here's how it works:</h2>
                <ol>
                  <li>
                    <b>Get Your QR Code:</b> By purchasing your own QR code, you
                    automatically become an affiliate marketer. Your unique
                    affiliate link is automatically included in your contact
                    information.
                  </li>
                  <li>
                    <b>Setup Your Payments:</b> Log into your Affiliate
                    Dashboard using the “Affiliate Login” button on The QR
                    Store’s home page. Enter the email address used for your
                    contact QR. Click the login link when received. Click on
                    “Your Payment Method” and use either your Paypal or Venmo
                    accounts to receive your commission payments.
                  </li>
                  <li>
                    <b>Share Effortlessly:</b> Place your etched QR tag on the
                    back of your phone or other prominent place where it's
                    easily accessible. Ask your friends, business associates,
                    potential customers or new acquaintances to simply scan your
                    QR code to instantly share your contact information. No more
                    “I don’t have my business cards with me” or hand written
                    notes! The convenience will leave a lasting impression, and
                    they want a QR code for themselves!
                    <p>
                      You can also share your contact information by text
                      message. Scan your QR and add your contact information to
                      your phone. Then click on the "mycontact_page" link in
                      your Contact. This link will take you to your hosted
                      contact page. Scroll to the bottom and click "Share your
                      contact page". Select the person you wish to text your
                      contact to.
                    </p>
                    <Grid
                      container
                      spacing={3}
                      className="share-effortless-div"
                    >
                      <Grid item xs={12} sm={5}>
                        {" "}
                        <img
                          src="/img/share-left.png"
                          className="share-left-image"
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} style={{ textAlign: "center" }}>
                        <img
                          src="/img/arrorw-right.png"
                          className="share-left-image"
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <img
                          src="/img/share-right.png"
                          className="share-right-image"
                        />
                      </Grid>
                    </Grid>
                  </li>
                  <li>
                    <b>Earn Commissions:</b> When someone clicks the affiliate
                    link embedded in your shared contact info and purchases
                    their own QR code, you earn a commission!
                    <Grid
                      container
                      spacing={3}
                      className="share-effortless-div"
                    >
                      <Grid item xs={12} sm={5}>
                        {" "}
                        <img
                          src="/img/share-comission.png"
                          alt="The Green Flash"
                          className="share-comission-image"
                        />
                      </Grid>
                    </Grid>
                  </li>
                  <li>
                    <b>Even More Earnings:</b> The earning potential doesn't
                    stop there. You'll also earn commissions on sales generated
                    by people who buy QRs through connections you made (people
                    who purchased from your affiliate link)!
                  </li>
                  <li>
                    <b>Monitor Your Marketing Efforts:</b> Log into your
                    Affiliate Dashboard to keep up with sales associated with
                    your affiliate link and the commissions you are earning.
                  </li>
                </ol>
                <p>
                  <b>This is a win-win situation!</b> You get a convenient way
                  to share your contact details and have the potential to earn
                  extra income.
                </p>
              </div>
            </Paper>
          </Container>
          <ModalSearchSection />
          <ModalMenuSection />
          <Footer />
        </div>
      </div>
    </div>
  );
}
