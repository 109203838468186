import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  IconButton,
  TextField,
} from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import axiosApiInstance from "../../store/axiosApi";
import Popover from "@mui/material/Popover";
import Pagination from "@mui/material/Pagination";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import BasicModal from "../BasicModal/affiliate_modal";
import Footer from "../Footer";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import LoadingDialog from "../Loading";
import CancelIcon from "@mui/icons-material/Cancel";

export default function DashboardDownstream() {
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedPopId, setSelectedPopId] = useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [token, setToken] = useState("");

  const [selectedDay, setSelectedDay] = useState(null);
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [level1, setLevel1] = useState(0);
  const [level2, setLevel2] = useState(0);
  const [level3, setLevel3] = useState(0);
  const [level4, setLevel4] = useState(0);
  const [level5, setLevel5] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState("all");
  const [monthUsers, setMonthUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastUser = page * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = monthUsers.slice(indexOfFirstUser, indexOfLastUser);

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const token = queryParams.get("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (currentMonth != null) fetchMonths();
  }, [selectedLevel]);

  useEffect(() => {
    if (selectedDay != null) fetchUsers();
  }, [selectedDay]);

  useEffect(() => {
    if (searchValue == "") handleSearch();
  }, [searchValue]);

  const displayNameInitials = (fullName) => {
    if (fullName == null) return "";
    const nameParts = fullName.trim().split(" ");
    // If there is only one part, return it as is
    if (nameParts.length === 1) {
      return fullName;
    }
    const firstName = nameParts[0];
    const restInitials = nameParts
      .slice(1)
      .map((part) => part.charAt(0) + ".")
      .join(" ");

    return `${firstName} ${restInitials}`;
  };

  const handleDateClick = (arg) => {
    setSelectedDay(arg.dateStr);
  };

  const handleDatesSet = (dateInfo) => {
    console.log("Datesset is called");
    const startDate = new Date(dateInfo.start);
    const endDate = new Date(dateInfo.end);
    const midDate = new Date((startDate.getTime() + endDate.getTime()) / 2);
    setCurrentMonth(midDate);
    setSelectedDay(null);
  };

  const handleEventClick = (arg) => {
    console.log(arg.event.startStr);
    setSelectedDay(arg.event.startStr);
  };

  const handleSearch = () => {
    if (selectedDay != null) fetchUsers();
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post("/downstream_users", {
        token: token,
        currentMonth,
        level: selectedLevel,
        selectedDay,
        searchValue,
      });
      console.log(response);
      setMonthUsers(response.data.monthUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching crypto orders:", error);
    }
  };

  const fetchMonths = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post("/downstream_months", {
        token: token,
        currentMonth,
        level: selectedLevel,
      });
      console.log(response);
      setEvents(response.data.monthResult);
      setLevel1(response.data.level1Month);
      setLevel2(response.data.level2Month);
      setLevel3(response.data.level3Month);
      setLevel4(response.data.level4Month);
      setLevel5(response.data.level5Month);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching crypto orders:", error);
    }
  };

  const showAffiliates = async (name, userId) => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const token = queryParams.get("token");
      const response = await axiosApiInstance.post("/get_affiliates", {
        token: token,
        userId,
      });
      console.log(response);
      if (response.data) {
        showModal(
          `Affiliate Details of ${name}`,
          `<br/> Level 1: ${response.data.level1Month} <br/> Level 2: ${response.data.level2Month} <br/> 
          Level 4: ${response.data.level3Month} <br/> Level 4: ${response.data.level4Month} <br/> Level 5: ${response.data.level5Month} <br/>`
        );
      } else {
        showModal("Affiliate Details", "No details available for this user.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching crypto orders:", error);
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <TransitionGroup>
          <CSSTransition
            key={eventInfo.event.date}
            classNames="fade"
            timeout={{ enter: 300, exit: 300 }}
          >
            <div className="event-content">{eventInfo.event.title}</div>
          </CSSTransition>
        </TransitionGroup>
      </>
    );
  };

  return (
    <div>
      {loading ? <LoadingDialog title="loading..." /> : <div />}
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"AFFILIATE DASHBOARD"}
          home={"Home"}
          page={"Dashboard"}
          title={"View Your Downstream Activity"}
          pageLink={`/dashboard?token=${token}`}
          showBreadcrumb="true"
        />
        <Container component="main" sx={{ mb: 4 }} className="mainContainer">
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
          >
            <React.Fragment>
              <Box sx={{ bgcolor: "background.paper" }}>
                <h3 style={{ textAlign: "center" }}>
                  View Your Downstream Activity
                </h3>
                <Grid container style={{ height: "80vh" }}>
                  <Grid item xs={12} md={6}>
                    <div className="level-select">
                      <div className="levels-summary">
                        <span>
                          <b>
                            Total = {level1 + level2 + level3 + level4 + level5}{" "}
                          </b>
                        </span>
                        <br />
                        <span>
                          <b>Level1 = {level1}, </b>
                        </span>
                        <span>
                          <b>Level2 = {level2}, </b>
                        </span>
                        <span>
                          <b>Level3 = {level3}, </b>
                        </span>
                        <span>
                          <b>Level4 = {level4}, </b>
                        </span>
                        <span>
                          <b>Level5 = {level5} </b>{" "}
                        </span>
                      </div>
                      <FormControl
                        style={{
                          marginLeft: "50px",
                          width: "80px",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Levels:
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          value={selectedLevel}
                          onChange={(e) => setSelectedLevel(e.target.value)}
                        >
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="level1">Level1</MenuItem>
                          <MenuItem value="level2">Level2</MenuItem>
                          <MenuItem value="level3">Level3</MenuItem>
                          <MenuItem value="level4">Level4</MenuItem>
                          <MenuItem value="level5">Level5</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      events={events}
                      dateClick={handleDateClick}
                      datesSet={handleDatesSet}
                      eventClick={handleEventClick}
                      editable={true}
                      selectable={true}
                      eventContent={renderEventContent}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="downstream-users-grid">
                    <p>
                      <b>{selectedDay ? `Details for ${selectedDay}` : null}</b>
                    </p>
                    <div className="downstream-users">
                      <TextField
                        fullWidth
                        label="Search name."
                        type="text"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") handleSearch();
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={() => {
                                setSearchValue("");
                              }}
                              style={{ marginRight: "-10px", padding: "0px" }}
                            >
                              <CancelIcon />
                            </IconButton>
                          ),
                        }}
                        style={{ width: "225px" }}
                      />{" "}
                      {monthUsers.length > 0 ? (
                        <Pagination
                          count={Math.ceil(monthUsers.length / rowsPerPage)}
                          page={page}
                          onChange={(event, newPage) => setPage(newPage)}
                          color="primary"
                          siblingCount={1}
                          boundaryCount={1}
                          style={{ marginLeft: "50px" }}
                        />
                      ) : null}
                    </div>

                    <TableContainer
                      style={{ height: "500px", overflow: "auto" }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell>Purchased</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>Activity</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {currentUsers.map((monthUser, index) => {
                            return (
                              <TableRow hover key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {displayNameInitials(monthUser.full_name)}
                                </TableCell>
                                <TableCell>
                                  Level {monthUser.levels_deep}
                                </TableCell>
                                <TableCell>{monthUser.date}</TableCell>
                                <TableCell>
                                  {displayNameInitials(
                                    monthUser.parent_affiliate
                                  )}
                                </TableCell>
                                <TableCell className="activity-count">
                                  {monthUser.count_affiliate > 0 ? (
                                    <span
                                      onClick={() =>
                                        showAffiliates(
                                          displayNameInitials(
                                            monthUser.full_name
                                          ),
                                          monthUser.id
                                        )
                                      }
                                    >
                                      {monthUser.count_affiliate}
                                    </span>
                                  ) : (
                                    0
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
            <Popover
              id={selectedPopId}
              open={popOpen}
              anchorEl={anchorEl}
              onClose={() => {
                setPopOpen(false);
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <CardContent className="userPopoverCard">
                <Typography>Full Name: {selectedUser?.full_name}</Typography>
                <hr />
                <Typography>
                  Address: {selectedUser?.street},{selectedUser?.city},{" "}
                  {selectedUser?.state} {selectedUser?.zipcode},{" "}
                  {selectedUser?.country}
                </Typography>
                <hr />
                <Typography>Email: {selectedUser?.email}</Typography>
                <hr />
                <Typography>
                  Phone: {selectedUser?.phone_mobile} {selectedUser?.phone_work}
                </Typography>
              </CardContent>
            </Popover>
            <BasicModal
              open={modalOpen}
              title={modalTitle}
              content={modalContent}
              showButton={false}
              handleClose={() => {
                setModalOpen(false);
              }}
              handleClick={() => {
                setModalOpen(false);
              }}
            />
          </Paper>
        </Container>
        <ModalSearchSection />
        <ModalMenuSection />
        <Footer />
      </div>
    </div>
  );
}
