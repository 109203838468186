import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  Link,
  SvgIcon,
  IconButton,
} from "@mui/material";
import {
  AddSharp,
  ArrowForwardIos,
  Directions,
  LinkSharp,
  Mail,
  NearMeSharp,
  Smartphone,
  Sms,
} from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { QrCode2Sharp, ShortcutSharp } from "@mui/icons-material";

import axiosApiInstance from "../../store/axiosApi";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

const galleryImages = [
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_1.png",
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_2.png",
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_1.png",
  "https://cdn0070.qrcodechimp.com/images/digitalCard/image_2.png",
];

export default function ContactPreview() {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.person);
  console.log("Preview", person);
  const [isLoading, setIsLoading] = useState(false);
  const [isQrcodePopupOpen, setQrcodePopupOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const isNonStandardPort =
    window.location.port && !["80", "443"].includes(window.location.port);
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    isNonStandardPort ? `:${window.location.port}` : ""
  }`;

  // const [preview, setPreview] = useState("");
  // const [qrData, setQRData] = useState("");

  // useEffect(() => {
  //   // setName("John Doe");
  //   // setEmail("john.doe@example.com");
  //   // setOrgName("Example Organization");
  //   // setPhone("123-456-7890");
  //   // setPhoneWork("098-765-4321");
  //   // setPosWork("Software Engineer");
  //   // setAddress("123 Main St, Anytown, CA 12345");
  //   // setStreet("123 Main St");
  //   // setCity("Anytown");
  //   // setState("CA");
  //   // setZipcode("12345");
  //   // setCountry("United States");
  //   // setAffiliateLink("http://www.ua.theqr.store?ac=971afcee");
  //   // setWebsite([
  //   //   "https://example.com",
  //   //   "https://facebook.com/johndoe",
  //   //   "https://instagram.com/johndoe",
  //   // ]);
  //   // setWebsiteTitle(["Website", "Facebook", "Instagram"]);
  //   // setPreview("/img/profile_1.webp");

  //   fetchData();
  // }, []);

  const handleQRCodeButtonClick = () => {
    setQrcodePopupOpen(true);
  };

  const handleQRCodePopupClose = () => {
    setQrcodePopupOpen(false);
  };

  const iconStyle = {
    backgroundColor: "#333", // Adjust the color to match your style
    color: "#fff",
    borderRadius: "50%",
    margin: "0 10px",
  };

  const handleClick = async () => {
    // if (selectedFile == null) {
    //   alert("Select Image.");
    //   return;
    // }
    setIsLoading(true);
    try {
      let binaryData = person.avatar;
      console.log(555, binaryData, person.userId);
      if (selectedFile != null) {
        binaryData = await resizeImgFile(selectedFile);
        console.log(5551, binaryData);
      }

      const response = await axiosApiInstance.post("/generateVCF", {
        imageContent: binaryData,
        session_id: person.userId,
      });

      const vCardData = response.data;
      const blob = new Blob([vCardData], { type: "text/vcard" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${person.firstName} ${person.lastName}.vcf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card sx={{ p: 2 }} className="preview_wrapper">
        <Typography variant="h5" gutterBottom className="preview-caption">
          Preview your contact website
        </Typography>
        <Box className="landing_page_preview_frame">
          <Box className="device-header"></Box>
          {isLoading ? (
            <Box className="qr_page_loader">
              <img className="loader_img" src="/qrlogo.png" alt="Loading..." />
            </Box>
          ) : (
            <Box
              id="qr_page_prev"
              sx={{
                zoom: 1,
                overflowX: "hidden",
                overflowY: "scroll",
                position: "relative",
                padding: 0,
              }}
              className="qr_page_preview qrp_prev_wrapper thinScrollBar"
            >
              {/* <Iframe 
              url="data:text/html;charset=utf-8,<html>...</html>" 
              className="landing_page_iframe" 
              width="100%" 
              height="798px"
            /> */}
              <Box className="page_wrapper">
                <Box className="qrc_page_wrapper thinScrollBar">
                  <Box
                    className="pg_background"
                    sx={{ backgroundImage: "url('')" }}
                  ></Box>
                  <Box className="qrc_page_inner">
                    {/* Profile Section */}
                    <Box className="section qrc_profile_3">
                      <Box className="qrc_profile_inner">
                        <Box
                          className="qrc_profilepic"
                          sx={{
                            backgroundPosition: "top center",
                            backgroundImage: `url(\'${
                              person.avatar || "/img/john_doe.png"
                            }\')`,
                          }}
                        >
                          <svg
                            id="Layer_1"
                            className="qrc_profile_3_svg"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 375 260"
                          >
                            <defs>
                              <style>{`
                            .cls-1 {
                              fill: url(#linear-gradient);
                            }
                          `}</style>
                              <linearGradient
                                id="linear-gradient"
                                x1="1.06"
                                y1="260.32"
                                x2="1.06"
                                y2="259.32"
                                gradientTransform="translate(-208.5 67424) scale(375 -259)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop
                                  offset="0"
                                  stopColor="#000"
                                  stopOpacity="0"
                                  style={{ stopColor: "var(--qrc-secondary)" }}
                                />
                                <stop
                                  offset="1"
                                  stopColor="#000"
                                  style={{ stopColor: "var(--qrc-secondary)" }}
                                />
                              </linearGradient>
                            </defs>
                            <rect
                              id="Rectangle_297"
                              data-name="Rectangle 297"
                              className="cls-1"
                              width="375"
                              height="260"
                            />
                          </svg>
                        </Box>
                        <Box className="qrc_profile_inner_info">
                          <Typography variant="h2">
                            {(person.firstName && person.firstName.trim()) ||
                            (person.lastName && person.lastName.trim())
                              ? `${person.firstName} ${person.lastName}`.trim()
                              : "Name"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {/* About Me Section */}
                    {(person.activeStep === 0 ||
                      person.organization ||
                      person.positionWork) && (
                      <Box className="section qrc_heading_text qr_cc_card">
                        <Typography variant="h2">
                          {person.organization ||
                            (person.activeStep === 0 ? "Company Name" : "")}
                        </Typography>
                        <Typography variant="body2">
                          {person.positionWork ||
                            (person.activeStep === 0 ? "Job Title" : "")}
                        </Typography>
                      </Box>
                    )}

                    {/* Contact Section */}
                    <Box className="section qrc_contact qr_cc_card">
                      <Box className="qrc_contact_header">
                        <IconButton style={iconStyle}>
                          <Smartphone />
                        </IconButton>
                        <Box className="qrc_contact_hdr_text">Contact</Box>
                      </Box>
                      <Box className="qrc_contact_info">
                        <div className="qrc_contact_info_title">
                          Phone (Mobile)
                        </div>
                        <a
                          href={`tel:${person.phoneMobile || "123 456 7890"}`}
                          className="qrc_contact_number"
                        >
                          {person.phoneMobile || "123 456 7890"}
                        </a>
                      </Box>
                      <Box className="qrc_contact_info">
                        <div className="qrc_contact_info_title">
                          Phone (Work)
                        </div>
                        <a
                          href={`tel:${person.phoneWork}`}
                          className="qrc_contact_number"
                        >
                          {person.phoneWork}
                        </a>
                      </Box>
                      <Box className="qrc_email_info">
                        <div className="qrc_email_info_title">Email</div>
                        <a
                          href={`mailto:${
                            person.email || "contactme@domain.com"
                          }`}
                          className="qrc_email_id"
                        >
                          {person.email || "contactme@domain.com"}
                        </a>
                      </Box>
                      <Box className="qrc_address_info">
                        <div className="qrc_address_info_title">Address</div>
                        {(person.activeStep === 0 ||
                          person.street ||
                          person.city ||
                          person.naState ||
                          person.zipCode ||
                          person.Country) && (
                          <>
                            <div className="qrc_address_text">
                              {person.street ||
                                (person.activeStep === 0 ? "Street" : "")}
                              <br />
                              {person.city ||
                                (person.activeStep === 0 ? "City" : "")}
                              ,{" "}
                              {person.naState ||
                                (person.activeStep === 0 ? "State" : "")}{" "}
                              {person.zipCode ||
                                (person.activeStep === 0 ? "Zipcode" : "")}
                              <br />
                              {person.country ||
                                (person.activeStep === 0 ? "USA" : "")}
                            </div>
                            <a
                              className="qrc_direction_btn"
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                person.street +
                                  ", " +
                                  person.city +
                                  ", " +
                                  person.naState +
                                  " " +
                                  person.zipCode +
                                  ", " +
                                  person.country
                              )}`}
                              target="_blank"
                            >
                              <NearMeSharp
                                style={{ color: "#fff", padding: "4px" }}
                              />
                              <span>Direction</span>
                            </a>
                          </>
                        )}
                        {/* <div className="qrc_address_text">{address}</div> */}
                      </Box>
                    </Box>

                    {/* Social Links Section */}
                    <Box className="section qrc_social_links">
                      <div className="qrc_heading">
                        <Typography variant="h2">Web Links</Typography>
                        {/* <Typography variant="body2">Description</Typography> */}
                      </div>
                      <ul className="qrc_social_links_list">
                        {(person.personalLink1 ||
                          person.personalLink1Title) && (
                          <li className="qrc_social_link">
                            <Link href={person.personalLink1} target="_blank">
                              <Box className="qrc_social_icon">
                                <IconButton>
                                  <LinkSharp />
                                </IconButton>
                              </Box>
                              <Box className="qrc_social_text">
                                <Typography variant="body1">
                                  {person.personalLink1Title}
                                </Typography>
                                <Typography variant="body2">
                                  {person.personalLink1}
                                </Typography>
                              </Box>
                              <Box className="qrc_social_action">
                                <IconButton>
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                            </Link>
                          </li>
                        )}

                        {(person.personalLink2 ||
                          person.personalLink2Title) && (
                          <li className="qrc_social_link">
                            <Link href={person.personalLink2} target="_blank">
                              <Box className="qrc_social_icon">
                                <IconButton>
                                  <LinkSharp />
                                </IconButton>
                              </Box>
                              <Box className="qrc_social_text">
                                <Typography variant="body1">
                                  {person.personalLink2Title}
                                </Typography>
                                <Typography variant="body2">
                                  {person.personalLink2}
                                </Typography>
                              </Box>
                              <Box className="qrc_social_action">
                                <IconButton>
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                            </Link>
                          </li>
                        )}

                        {(person.personalLink3 ||
                          person.personalLink3Title) && (
                          <li className="qrc_social_link">
                            <Link href={person.personalLink3} target="_blank">
                              <Box className="qrc_social_icon">
                                <IconButton>
                                  <LinkSharp />
                                </IconButton>
                              </Box>
                              <Box className="qrc_social_text">
                                <Typography variant="body1">
                                  {person.personalLink3Title}
                                </Typography>
                                <Typography variant="body2">
                                  {person.personalLink3}
                                </Typography>
                              </Box>
                              <Box className="qrc_social_action">
                                <IconButton>
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                            </Link>
                          </li>
                        )}

                        {person.activeStep == -1 && person.userId && (
                          <li className="qrc_social_link">
                            <Link
                              href={`${baseUrl}/affiliate_link/${person.userId}`}
                              target="_blank"
                            >
                              <Box className="qrc_social_icon">
                                <IconButton>
                                  <LinkSharp />
                                </IconButton>
                              </Box>
                              <Box className="qrc_social_text">
                                <Typography variant="body1">
                                  Affiliate Link
                                </Typography>
                                <Typography variant="body2">
                                  {`${baseUrl}/affiliate_link/${person.userId}`}
                                </Typography>
                              </Box>
                              <Box className="qrc_social_action">
                                <IconButton>
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                            </Link>
                          </li>
                        )}

                        {person.activeStep == -1 && person.userId && (
                          <li className="qrc_social_link">
                            <Link
                              href={`sms:?&body=Check out my contact link: ${baseUrl}/mycontact_page/${person.userId}`}
                              target="_blank"
                            >
                              <Box className="qrc_social_icon">
                                <IconButton>
                                  <Sms />
                                </IconButton>
                              </Box>
                              <Box className="qrc_social_text">
                                <Typography variant="body1">
                                  Share your contact page
                                </Typography>
                                <Typography variant="body2">
                                  {`${baseUrl}/mycontact_page/${person.userId}`}
                                </Typography>
                              </Box>
                              <Box className="qrc_social_action">
                                <IconButton>
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </Box>

                    {/* Social Links Section */}
                    <Box className="section qrc_caption">
                      <Typography variant="h6">
                        <strong>The QR Store</strong>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Extra Buttons */}
              {!isLoading && (
                <Box
                  className="extra_button_wrapper"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <IconButton
                      style={{ ...iconStyle, margin: "0px 5px" }}
                      onClick={handleQRCodeButtonClick}
                    >
                      <QrCode2Sharp />
                    </IconButton>
                  </div>
                  <div
                    className={`qrc_addtocontact ${
                      person.activeStep !== -1 && "qrc_addtocontact_disabled"
                    }`}
                    onClick={() => {
                      if (true) handleClick();
                    }}
                  >
                    <Box className="qrc_addtocontact_text">Add to Contact</Box>
                    <Box className="qrc_addtocontact_circle">
                      <AddSharp />
                    </Box>
                  </div>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Card>
      <Dialog
        open={isQrcodePopupOpen}
        onClose={handleQRCodePopupClose}
        aria-labelledby="qr-dialog-title"
        PaperProps={{
          className: "dashboard qr-dialog",
        }}
      >
        <DialogTitle id="qr-dialog-title">
          QR Code
          <IconButton
            aria-label="close"
            onClick={handleQRCodePopupClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div id="qrlabel-area">
            <h3 className="qrlabel-preview-text">{person.labelText}</h3>
            {(() => {
              const base64Image = `data:image/png;base64, ${person.qrData}`;
              return (
                <img
                  src={base64Image}
                  alt="QR Code"
                  className="qr-preview-img"
                  style={{ marginTop: "15px" }}
                />
              );
            })()}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleQRCodePopupClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              const link = document.createElement("a");
              link.href = `data:image/png;base64, ${person.qrData}`;
              link.download = "QR_Code.png";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
            color="primary"
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
