import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import {useSelector, useDispatch} from 'react-redux';
import {
  setFirstName,
  setLastName,
  setOrganization,
  setPositionWork,
  setPhoneWork,
  setPhoneMobile,
  setConfirmPhoneMobile,
  setEmail,
  setConfirmEmail,
  setWebsite,
  setStreet,
  setZipCode,
  setCity,
  setNaState,
  setCountry,
  setErrors,
  setLoadingTitle,
  setSelectLocationOption,
  setChecked,
  setUserId,
  setForegroundPreview,
  setCustomQrPreview,
  setPreview,
  setSelectedFile,
  setPerson,
} from '../../store/contactInfoSlice';

export default function PurchaseForm () {
  const dispatch = useDispatch ();
  const person = useSelector (state => state.person);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        You can purchase a personalized QR code via Stripe.
      </Typography>
      {person.customQrPreview
        ? <div style={{textAlign: 'center'}}>
            <img
              src={person.customQrPreview}
              style={{width: 350}}
              alt="processed"
              title="processed"
            />
            <p>
              After purchase your QR will contain the correct name,
              phone number.
            </p>
          </div>
        : <div />}
    </React.Fragment>
  );
}
