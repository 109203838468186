import * as React from "react";

import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import ServiceChoose from "./ServiceChoose";

export default function QRService() {
  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"CREATE YOUR PERSONALIZED QR"}
          home={"Home"}
          page={"Purchase Page"}
          title={"Personalized QR"}
        />
        <ServiceChoose />
        <ModalSearchSection />
        <ModalMenuSection />
      </div>
    </div>
  );
}
