import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import BasicModal from "../BasicModal/modal";
import LoadingDialog from "../Loading";
import axiosApiInstance from "../../store/axiosApi";

export default function EZProductPage() {
  const { store_id = 'ezstore', product_id = 'greenflash' } = useParams();

  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [storeName, setStoreName] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [information, setInformation] = useState("");
  const [price, setPrice] = useState("");

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axiosApiInstance.post("/getProduct", {
        store_id,
        product_id
      });
      if (response.data.success) {
        setLoading(false);
        console.log(response.data.product);
        setStoreName(response.data.product.store_name);
        setProductName(response.data.product.product_name);
        setDescription(response.data.product.description);
        setImageSrc(response.data.product.image_url);
        setInformation(response.data.product.information);
        setPrice(response.data.product.price);
      } else {
        showModal("Error", "Failed to load product info.");
      }
      
      setLoading(false);
    } catch (error) {
      console.log(error);
      showModal("Error", "Failed to load product info.");
      setLoading(false);
    }
  };

  const handlePurchase = async () => {
    try {
      setLoading(true);

      const response = await axiosApiInstance.post("/ezStoreCheckout", {
        store_id,
        product_id
      });
      if (response.status == 200) {
        window.open(response.data.redirectUrl, "_blank");
      } else {
        showModal("Error", "Failed to purchase the EZStore product.");
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      showModal("Error", "Failed to purchase the EZStore product.");
    }
  };
  
  useEffect(() => {
    // setStoreName("EZStore");
    // setImageSrc("/img/EZStore-GreenFlashWith Signature.png");
    // setProductName("Elusive Green Flash - Digital Download");
    // setDescription(
    //   "An artistic rendition of the rare green flash phenomenon witnessed at Miramar Beach FL. \
    //   The original photograph was taken on October 27, 2023, from the deck of The Whale's Tail restaurant. \
    //   The scene captures the fleeting green flash as the sun dips below the horizon, casting its unique glow over the tranquil waters."
    // );
    // setInformation(
    //   "Get instant access to this stunning poster! \
    //   You can download it directly to your computer and print it at your convenience. \
    //   Upon purchase, you'll receive a downloadable file containing the poster artwork."
    // );
    // setPrice("9.95");

    fetchData();
  }, []);

  return (
    <>
      {loading ? <LoadingDialog /> : 
      <div className="store-container">
        <div className="product-card">
          <h3 className="store-name">
           {storeName}
          </h3>
          <img src={imageSrc} alt={productName} className="product-image" />
          <h2 className="product-title">{productName}</h2> 
          <p className="product-description">
            {description}
          </p>
          <p className="product-info">
            {information}
          </p>
          <p className="product-price">
            ${price}
          </p>
          <button className="purchase-button" onClick={handlePurchase}>Purchase</button>
        </div>
      </div>}
      <BasicModal
        open={modalOpen}
        title={modalTitle}
        content={modalContent}
        showButton={false}
        handleClose={() => setModalOpen(false)}
        handleClick={() => setModalOpen(false)}
      />
    </>
  );
};
