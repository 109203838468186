import * as React from 'react';

import ScrollupSection from '../Scrollup/Scrollup';
import Header from '../Header/HeaderOne';
import BreadcrumbSection from '../Breadcrumb/BreadcrumbOne';
import ModalSearchSection from '../Modal/ModalSearch';
import ModalMenuSection from '../Modal/ModalMenu';
import DashboardTabs from './DashboardTabs';

export default function FullWidthTabs () {
  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={'/img/logo-white.png'} />
        {' '}
        <BreadcrumbSection
          heading={'ADMIN DASHBOARD'}
          home={'Home'}
          page={'ADMIN DASHBOARD'}
          title={'ADMIN DASHBOARD'}
        />
        {' '}
        <DashboardTabs />
        <ModalSearchSection />
        <ModalMenuSection />
      </div>{' '}
    </div>
  );
}
