import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  open,
  title,
  content,
  showButton,
  handleClose,
  handleClick = null,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="basic-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: content }} />

          {showButton && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="btn-checkout"
                variant="contained"
                onClick={handleClick}
                sx={{ mt: 3, ml: 1 }}
              >
                Close
              </Button>
              {/* <Button
                className="btn-checkout"
                variant="contained"
                color="grey"
                onClick={handleClose}
                sx={{ mt: 3, ml: 1 }}
              >
                Cancel
              </Button> */}
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
