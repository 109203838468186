import axios from "axios";
import appConfig from "../config/config";

const apiBaseURL = appConfig.API_ENDPOINT;
const baseToken = appConfig.API_TOKEN;

const commonHeaders = {
  "x-api-key": baseToken,
};

const axiosApiInstance = axios.create({
  baseURL: apiBaseURL,
  headers: commonHeaders,
});

export default axiosApiInstance;
