import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import axiosApiInstance from "../../store/axiosApi";
import Popover from "@mui/material/Popover";
import Pagination from "@mui/material/Pagination";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import TextField from "@mui/material/TextField";
import LoadingDialog from "../Loading";
import BasicModal from "../BasicModal/modal";
import constants from "../../config/constants";
import { NumericFormat } from "react-number-format";
import numeral from "numeral";
import Footer from "../Footer";

export default function AffiliateCalculator() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(-1);
  const [email, setEmail] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\d+$/;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [level1, setLevel1] = useState(0);
  const [level2, setLevel2] = useState(0);
  const [level3, setLevel3] = useState(0);
  const [level4, setLevel4] = useState(0);
  const [level5, setLevel5] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const formattedCurrency = numeral(totalSum).format("$0,0");
  const [token, setToken] = useState("");

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const earningLevel1 = parseInt(level1);
    const earningLevel2 = parseInt(level1) * parseInt(level2);
    const earningLevel3 =
      parseInt(level1) * parseInt(level2) * parseInt(level3);
    const earningLevel4 =
      parseInt(level1) * parseInt(level2) * parseInt(level3) * parseInt(level4);
    const earningLevel5 =
      parseInt(level1) *
      parseInt(level2) *
      parseInt(level3) *
      parseInt(level4) *
      parseInt(level5);

    setTotalSum(
      earningLevel1 +
        earningLevel2 +
        earningLevel3 +
        earningLevel4 +
        earningLevel5
    );
  }, [level1, level2, level3, level4, level5]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setToken(token);

      const response = await axiosApiInstance.post("/profile", {
        // const response = await axiosApiInstance.post("/api/profile", {
        token: token,
      });
      if (response.data.success) {
        setLoading(false);
        console.log(response.data.user);
        setUserInfo(response.data.user);
        setSelectedPaymentMethod(response.data.user.fund_method);
        setEmail(response.data.user.fund_email);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.msg);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingDialog />
      ) : (
        <div />
      )}
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={"/img/logo-white.png"} />
          <BreadcrumbSection
            heading={"AFFILIATE DASHBOARD"}
            home={"Home"}
            page={"Dashboard"}
            title={"Affiliate Calculator"}
            pageLink={`/dashboard?token=${token}`}
            showBreadcrumb="true"
          />
          <Container component="main" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
            >
              <React.Fragment>
                <Box sx={{ bgcolor: "background.paper" }}>
                  <div
                    className="level-affliates"
                    style={{ textAlign: "center" }}
                  >
                    <h3>
                      Hello, {userInfo?.full_name || 'Dear'}! Calculate Your Affiliate
                      Commission
                    </h3><p>
                      (Affiliates are paid $1 for every QR sale in their
                      levels)
                    </p>
                    <h4>Your Earning : {formattedCurrency}</h4>
                    <br />
                    <br />

                    <Grid container spacing={3} className="calculator-group">
                      <Grid item xs={12} sm={8}>
                        <h4> Level 1</h4>
                        <p className="calculator-level-question">
                          How many people that scan your QR code will purchase
                          their own?
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumericFormat
                          customInput={TextField}
                          allowLeadingZeros
                          thousandSeparator=","
                          onValueChange={({ value: v }) => setLevel1(v)}
                          className="calculator-input"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className="calculator-group">
                      <Grid item xs={12} sm={8}>
                        <h4> Level 2</h4>
                        <p className="calculator-level-question">
                          How many people will purchase QRs from each of your
                          Level 1 affiliates?
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumericFormat
                          customInput={TextField}
                          allowLeadingZeros
                          thousandSeparator=","
                          onValueChange={({ value: v }) => setLevel2(v)}
                          className="calculator-input"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className="calculator-group">
                      <Grid item xs={12} sm={8}>
                        <h4> Level 3</h4>
                        <p className="calculator-level-question">
                          How many people will purchase QRs from each of your
                          Level 2 affiliates?
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumericFormat
                          customInput={TextField}
                          allowLeadingZeros
                          thousandSeparator=","
                          onValueChange={({ value: v }) => setLevel3(v)}
                          className="calculator-input"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className="calculator-group">
                      <Grid item xs={12} sm={8}>
                        <h4> Level 4</h4>
                        <p className="calculator-level-question">
                          How many people will purchase QRs from each of your
                          Level 3 affiliates?
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumericFormat
                          customInput={TextField}
                          allowLeadingZeros
                          thousandSeparator=","
                          onValueChange={({ value: v }) => setLevel4(v)}
                          className="calculator-input"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} className="calculator-group">
                      <Grid item xs={12} sm={8}>
                        <h4> Level 5</h4>
                        <p className="calculator-level-question">
                          How many people will purchase QRs from each of your
                          Level 4 affiliates?
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <NumericFormat
                          customInput={TextField}
                          allowLeadingZeros
                          thousandSeparator=","
                          onValueChange={({ value: v }) => setLevel5(v)}
                          className="calculator-input"
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <BasicModal
                    open={modalOpen}
                    title={modalTitle}
                    content={modalContent}
                    showButton={false}
                    handleClose={() => {
                      setModalOpen(false);
                    }}
                    handleClick={() => {
                      setModalOpen(false);
                    }}
                  />
                </Box>
              </React.Fragment>
            </Paper>
          </Container>
          <ModalSearchSection />
          <ModalMenuSection />
          <Footer />
        </div>
      </div>
    </div>
  );
}
