import React, { Component, useState } from "react";
import Alert from "@mui/material/Alert";
import axiosApiInstance from "../../store/axiosApi";
import LoadingDialog from "../Loading";
import { Typography } from "@mui/material";
import constants from "../../config/constants";

export default function ContactOne() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [responstStatus, setReponseStatus] = useState("success");
  const [loadingTitle, setLoadingTitle] = useState("sending...");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    nameValidaton: "",
    emailValidation: "",
    phoneValidation: "",
    messageValidaton: "",
  });

  const handleClick = async () => {
    try {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
      const nameValidaton = name != "" ? "" : "Name is required.";
      const emailValidation = emailPattern.test(email)
        ? ""
        : "Email is not valid.";
      const phoneValidation = phonePattern.test(phone)
        ? ""
        : "Phone number is not valid.";
      const messageValidaton = message != "" ? "" : "Message is required.";

      setErrors({
        nameValidaton,
        emailValidation,
        phoneValidation,
        messageValidaton,
      });

      if (
        nameValidaton != "" ||
        emailValidation != "" ||
        phoneValidation != "" ||
        messageValidaton != ""
      ) {
        return;
      }
      setLoading(true);
      const postResponse = await axiosApiInstance.post("/send_message", {
        name,
        email,
        phone,
        message,
      });
      setResponseMessage(postResponse.data.message);
      setReponseStatus("success");
      setLoading(false);
    } catch (error) {
      setResponseMessage(constants.serverConnectionErrMsg);
      setReponseStatus("error");
      setLoading(false);
    }
  };

  return (
    <section id="contact" className="contact-area ptb_100">
      {isLoading ? <LoadingDialog title={loadingTitle} /> : <div />}
      <div className="container d-flex justify-content-center align-items-center">
        <div className="contact-box text-center">
          {/* Contact Form */}
          <form id="contact-form">
            <Typography variant="h3">Let's connect!</Typography>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    required="required"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {errors.nameValidaton != "" ? (
                    <p className="error-text error-text-contact-us">
                      {errors.nameValidaton}
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    required="required"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {errors.emailValidation != "" ? (
                    <p className="error-text error-text-contact-us">
                      {errors.emailValidation}
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Phone"
                    required="required"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                  {errors.phoneValidation != "" ? (
                    <p className="error-text error-text-contact-us">
                      {errors.phoneValidation}
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    required="required"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  {errors.messageValidaton != "" ? (
                    <p className="error-text error-text-contact-us">
                      {errors.messageValidaton}
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-bordered active btn-block mt-3"
                  onClick={handleClick}
                >
                  <span className="text-white pr-3">
                    <i className="fas fa-paper-plane" />
                  </span>
                  Send Message
                </button>
                {responseMessage != "" && (
                  <Alert
                    style={{ marginTop: "25px" }}
                    severity={responstStatus}
                  >
                    {responseMessage}
                  </Alert>
                )}
              </div>
            </div>
          </form>
          <p className="form-message" />
        </div>
      </div>
    </section>
  );
}
