import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import axiosApiInstance from "../../store/axiosApi";
import Resizer from "react-image-file-resizer";
import { useSelector, useDispatch } from "react-redux";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ImageIcon from "@mui/icons-material/Image";
import UploadFileIcon from "@mui/icons-material/FileUpload";
import Divider from "@mui/material/Divider";
import constants from "../../config/constants";
import {
  setFirstName,
  setLastName,
  setOrganization,
  setPositionWork,
  setPhoneWork,
  setPhoneMobile,
  setConfirmPhoneMobile,
  setEmail,
  setConfirmEmail,
  setWebsite,
  setStreet,
  setZipCode,
  setCity,
  setNaState,
  setCountry,
  setErrors,
  setLoading,
  setLoadingTitle,
  setSelectLocationOption,
  setChecked,
  setUserId,
  setForegroundPreview,
  setCustomQrPreview,
  setPreview,
  setSelectedFile,
  setAffiliateLink,
  setPerson,
  setActiveStep,
  setShowAdditionalContent,
} from "../../store/contactInfoSlice";
import { useNavigate } from "react-router-dom";

export default function CustomQR({ showModal }) {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.person);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setShowAdditionalContent(false));
  }, []);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("personData"));
    if (savedData) {
      dispatch(setPerson(savedData));
    }
  }, [dispatch]);

  useEffect(() => {
    const personData = { ...person, activeStep: person.activeStep };
    localStorage.setItem("personData", JSON.stringify(personData));
  }, [person]);

  const handleBack = () => {
    const updatedPersonData = {
      ...person,
      processedImage: "",
      activeStep: person.activeStep - 1,
      affiliateLink: "",
    };
    localStorage.setItem("personData", JSON.stringify(updatedPersonData));
    dispatch(setActiveStep(person.activeStep - 1));
    //  window.location.reload()
  };

  const handleShow = () => {
    dispatch(
      setPerson({
        showAdditionalContent: false, // in case of adding custom pictures, set this value true
        preview: "",
        checked: false,
        selectedFile: null,
        customQrPreview: "",
      })
    );

    navigate(`/qr_label/${person.userId}`);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (!file) return;

    if (file.size > maxSize) {
      showModal(
        "Notification",
        "File size exceeds 5MB. Please upload a smaller file."
      );
      event.target.value = "";
      return;
    }

    dispatch(
      setPerson({ selectedFile: event.target.files[0], checked: false })
    );
  };

  const handleAddImage = () => {
    handlePurchase(person.selectedLocationOption);
  };

  const handleLocationChange = (e) => {
    dispatch(setSelectLocationOption(e.target.value));
  };

  const resizeImgFile = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          let newWidth;
          let newHeight;

          if (width > height) {
            newWidth = 350;
            newHeight = undefined;
          } else {
            newWidth = undefined;
            newHeight = 350;
          }

          Resizer.imageFileResizer(
            file,
            newWidth,
            newHeight,
            "JPEG",
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            "base64"
          );
        };
      };
      reader.readAsDataURL(file);
    });

  const handlePurchase = async (selectedLocation) => {
    if (person.selectedFile == null || person.selectedFile == "") {
      showModal("Notification", "Please select your image.");
      return;
    }
    if (person.userId == null) {
      showModal(
        "Notification",
        "Please register your personal information and click the Create button."
      );
      return;
    }
    let userimage;
    if (person.checked) userimage = person.foregroundPreview;
    else {
      const binaryData = await resizeImgFile(person.selectedFile);
      userimage = binaryData;
    }

    const formData = {
      image: userimage,
      userId: person.userId,
      delta: selectedLocation,
    };

    try {
      dispatch(
        setPerson({
          isLoading: true,
          loadingTitle: "Generating your personalized QR",
        })
      );
      const response = await axiosApiInstance.post("/qr_custom", formData);

      dispatch(
        setPerson({
          isLoading: false,
          loadingTitle: "Generating your personalized QR",
          customQrPreview: "data:image/png;base64," + response.data.imageData,
        })
      );
    } catch (error) {
      showModal("Notification", constants.serverConnectionErrMsg);
      dispatch(setLoading(false));
    }
  };

  const handleProcessingBackgroundChange = async (event) => {
    if (person.selectedFile == null || person.selectedFile == "") {
      showModal("Notification", "Please select your image.");
      return;
    }
    // dispatch (setPerson ({checked: event.target.checked}));
    dispatch(setPerson({ checked: !person.checked }));
    // if (event.target.checked && person.foregroundPreview == undefined) {
    if (!person.checked && person.foregroundPreview == undefined) {
      dispatch(
        setPerson({
          isLoading: true,
          loadingTitle: "Removing your image background.",
        })
      );
      const binaryData = await resizeImgFile(person.selectedFile);
      try {
        const postResponse = await axiosApiInstance.post(
          "/remover_background",
          {
            image: binaryData,
          }
        );
        dispatch(
          setPerson({
            foregroundPreview: "data:image/png;base64," + postResponse.data,
            isLoading: false,
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(
          setPerson({
            isLoading: false,
            loadingTitle: "Removing your image background.",
          })
        );
        showModal("Notification", constants.serverConnectionErrMsg);
      }
    }
  };

  useEffect(() => {
    if (!person.selectedFile) {
      dispatch(setPreview(undefined));
      return;
    }

    let objectURL;
    try {
      objectURL = URL.createObjectURL(person.selectedFile);
    } catch (error) {
      console.error("Failed to create object URL:", error);
      return;
    }

    dispatch(setPerson({ foregroundPreview: undefined, preview: objectURL }));

    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [person.selectedFile, dispatch]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom className="preview-caption">
        Your Personalized Contact QR Code
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="text-center">
            {person.processedImage ? (
              <>
                <img
                  src={`data:image/png;base64,${person.processedImage}`}
                  style={{ width: 350, height: 350 }}
                  alt="Processed"
                  title="Not process."
                />
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontSize: "1.2rem" }}
                >
                  Please use your phone's "Camera" to scan your QR. <br />
                  If the information is correct please click "Next". If you want
                  to make changes please click "Back".
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginTop: "100px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-checkout btn-custom-prev-next"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn-checkout btn-custom-prev-next"
                    onClick={handleShow}
                  >
                    Next
                  </Button>
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
          <div
            className={`timeline-container ${
              /*person.showAdditionalContent*/ false
                ? "visible-content"
                : "hidden-content"
            }`}
          >
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot>
                    <UploadFileIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "20px", px: 2 }}>
                  <Typography>
                    To personalize your QR code with a picture or logo, click
                    the "Upload File" button below.
                    <br />
                    <br />
                    If you don't want to add a picture or logo, simply click the
                    "Purchase" button.
                    <br />
                    <br />
                    Please note that the size of the image is limited to 5MB.
                    Image ( jpeg, jpg, png )
                  </Typography>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      &nbsp;
                    </FormLabel>
                  </FormControl>
                  <Button
                    className="btn-checkout btn-submit"
                    variant="contained"
                    component="label"
                  >
                    Upload File
                    <input
                      type="file"
                      hidden
                      accept=".jpeg, .jpg, .png|image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <div className="divider-line" />
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary" variant="outlined">
                    <ImageIcon />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "20px", px: 2 }}>
                  <FormControl>
                    <div className="text-center">
                      {person.checked ? (
                        person.foregroundPreview ? (
                          <img
                            src={person.foregroundPreview}
                            className="original-background-image"
                            alt="processed"
                            title="processed"
                          />
                        ) : (
                          <img
                            src={person.preview}
                            className="removed-background-image"
                            alt="processed"
                            title="processed"
                          />
                        )
                      ) : person.preview ? (
                        <img
                          src={person.preview}
                          className="removed-background-image"
                          alt="processed"
                          title="processed"
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                    <Typography>
                      Removing the background from a person's picture often
                      enhances its overall appearance. However, it's essential
                      to note that background removal may not always yield
                      perfect results for every image.
                      <br />
                      <br />
                      If you encounter any issues, it's worth considering using
                      a different photo. Image ( jpeg, jpg, png )
                    </Typography>
                    {/* <FormControlLabel
                      control={
                        <Switch
                    FormControl      true
                          onChange={handleProcessingBackgroundChange}
                          checked={person.checked == true}
                        />
                      }
                      label="Remove Background"
                    /> */}
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      &nbsp;
                    </FormLabel>
                  </FormControl>
                  <Button
                    className="btn-checkout btn-submit"
                    variant="contained"
                    component="label"
                    onClick={handleProcessingBackgroundChange}
                  >
                    {!person.checked
                      ? "Remove Background"
                      : "Restore Background"}
                  </Button>
                  <div className="divider-line" />
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="primary" variant="outlined">
                    <QrCode2Icon />
                  </TimelineDot>
                  <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: "20px", px: 2 }}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Image Location
                    </FormLabel>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="center"
                          control={<Radio />}
                          label="Center"
                          checked={person.selectedLocationOption == "center"}
                          onChange={handleLocationChange}
                        />
                        <FormControlLabel
                          value="bottom"
                          control={<Radio />}
                          label="Bottom Center"
                          checked={person.selectedLocationOption == "bottom"}
                          onChange={handleLocationChange}
                        />
                        <FormControlLabel
                          value="right"
                          control={<Radio />}
                          label="Bottom Right"
                          checked={person.selectedLocationOption == "right"}
                          onChange={handleLocationChange}
                        />
                      </RadioGroup>
                    </div>
                    <Button
                      className="btn-checkout"
                      variant="contained"
                      onClick={handleAddImage}
                    >
                      Add Image
                    </Button>
                    <Typography>
                      <br />
                      Select where you want the image to appear on the your QR
                      and click "Add Image".
                    </Typography>
                  </FormControl>
                </TimelineContent>
              </TimelineItem>
            </Timeline>

            <div className="form-group">
              {person.customQrPreview ? (
                <div>
                  <div className=" text-center">
                    <img
                      src={person.customQrPreview}
                      style={{ height: "350px" }}
                      alt="processed"
                      title="processed"
                    />
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
