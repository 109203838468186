import React, { Component } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import Footer from "../Footer";

export default function AffiliateGuide() {
  return (
    <>
      <section
        className="section content-area bg-grey ptb_150"
        style={{ marginTop: "10px" }}
      >
        {/* Shape Top */}
        <div className="shape shape-top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
            fill="#FFFFFF"
          >
            <path
              className="shape-fill"
              d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                          c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                          c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
            />
          </svg>
        </div>
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-12 col-lg-6">
              <div class="content-inner text-center">
                <div class="section-heading text-center mb-3">
                  <h2>Turn your QR code into a money-making machine!</h2>
                  <p class="d-none d-sm-block mt-4">
                    With our affiliate program, you can earn money every time
                    someone purchases a personalized QR code using your unique
                    affiliate link.
                  </p>
                </div>
                <ul class="content-list text-left affiliate-program-guide">
                  <li class="single-content-list media py-2">
                    <div class="content-icon pr-4">
                      <span class="color-1">
                        <svg
                          class="svg-inline--fa fa-angle-double-right fa-w-14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="angle-double-right"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        />{" "}
                        <i class="fas fa-angle-double-right" />{" "}
                      </span>
                    </div>
                    <div class="content-text media-body ">
                      <span>
                        <b>Benefits of becoming an affiliate</b>
                        <ul>
                          <li>
                            <Typography>
                              • <b>Easy to get started</b>: Everyone who purchases
                              a personalized QR code automatically becomes an
                              affiliate.
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              • <b>Passive income</b>: Simply show off your
                              personalized QR code and get others to purchase
                              their own
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              • <b>Track your earnings</b>: Log in to your
                              Affiliate Dashboard to see who has purchased using
                              your affiliate link and how much money you have
                              made.
                            </Typography>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </li>
                  <li class="single-content-list media py-2">
                    <div class="content-icon pr-4">
                      <span class="color-1">
                        <svg
                          class="svg-inline--fa fa-angle-double-right fa-w-14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="angle-double-right"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          data-fa-i2svg=""
                        />{" "}
                        <i class="fas fa-angle-double-right" />{" "}
                      </span>
                    </div>
                    <div class="content-text media-body ">
                      <span>
                        <b>How it works</b>
                        <br />
                        <ul>
                          <li>
                            <Typography>
                              • <b>Get your personalized QR code</b>: Create and
                              purchase your personalized QR code on our website.
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              • <b>Become an affiliate</b>: Your unique affiliate
                              link is automatically added to your QR information.
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              • <b>Share your QR code</b>: Show off your
                              personalized QR code and encourage others to
                              purchase their own.
                            </Typography>
                          </li>
                          <li>
                            <Typography>
                              • <b>Earn money</b>: When someone purchases using
                              your affiliate link, you get credit for their
                              purchase.
                            </Typography>
                          </li>
                        </ul>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="service-thumb mx-auto pt-4 pt-lg-0">
                <img
                  alt=""
                  src="/img/affiliate_background.webp"
                  style={{ marginTop: "35px" }}
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Shape Bottom */}
        <div className="shape shape-bottom">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
            fill="#FFFFFF"
          >
            <path
              className="shape-fill"
              d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
            />
          </svg>
        </div>
      </section>
      <Footer />
    </>
  );
}
