import React, {Component} from 'react';

import ScrollupSection from '../Scrollup/Scrollup';
import Header from '../Header/HeaderOne';
import BreadcrumbSection from '../Breadcrumb/BreadcrumbOne';
import ModalSearchSection from '../Modal/ModalSearch';
import ModalMenuSection from '../Modal/ModalMenu';
import CheckoutForm from './Checkout';

class Purchase extends Component {
  render () {
    return (
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={'/img/logo-white.png'} />
          <BreadcrumbSection
            heading={'CREATE YOUR PERSONALIZED QR'}
            home={'Home'}
            page={'Purchase Page'}
            title={'Personalized QR'}
          />
          <CheckoutForm />
          <ModalSearchSection />
          <ModalMenuSection />
        </div>
      </div>
    );
  }
}

export default Purchase;
