import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbOne";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import { useSelector, useDispatch } from "react-redux";
import { setPerson } from "../../store/contactInfoSlice";
import axiosApiInstance from "../../store/axiosApi";
import { useParams } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import LoadingDialog from "../Loading";
import BasicModal from "../BasicModal/affiliate_modal";

export default function QRLabel() {
  const { session_id } = useParams();
  const person = useSelector((state) => state.person);
  const [isLoading, setIsLoading] = useState(false);
  const [qrData, setQRData] = useState("");
  const [labelText, setLabelText] = useState("The QR Store");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [showButton, setShowButton] = useState("");

  const dispatch = useDispatch();

  console.log(person);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("personData"));
    if (savedData) {
      dispatch(setPerson(savedData));
    }
  }, [dispatch]);

  useEffect(() => {
    if (session_id != null) fetchData();
  }, [session_id]);

  const handleStripeGateway = async (e) => {
    setIsLoading(true);
    dispatch(setPerson({ isLoading: true, loadingTitle: "Please wait..." }));
    await axiosApiInstance
      .post(
        "/create_payment_intent",
        {
          userId: session_id,
          delta: person.selectedLocationOption,
          purchasePod: false,
          labelText,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        if (res.data.redirectUrl == null || res.data.redirectUrl == undefined) {
          showModal("Notification", res.data.message);
          setIsLoading(false);
          return;
        }
        window.open(res.data.redirectUrl);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosApiInstance.post(`/getMyQR`, {
        session_id,
      });
      if (response.data.success) {
        setQRData(response.data.qrData);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle the error here. Could be a simple console log or error message display.
      console.error(error);
      setIsLoading(false);
    }
  };

  const showModal = (title, content, showed = false) => {
    setShowButton(false);
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  return (
    <div>
      {" "}
      {isLoading ? <LoadingDialog /> : <div />}
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />
        <BreadcrumbSection
          heading={"QR Label"}
          home={"Home"}
          page={"Purchase Page"}
          title={"Personalized QR"}
        />
        <React.Fragment>
          <CssBaseline />
          <Container component="main" className="checkout">
            <Grid
              container
              spacing={2}
              sx={{ display: { xs: "block", sm: "flex" } }}
            >
              <Grid item xs={12} sm={8}>
                <Paper
                  variant="outlined"
                  sx={{
                    my: { xs: 3, md: 2 },
                    p: { xs: 2, md: 3 },
                    minHeight: "400px",
                  }}
                >
                  <p className="label-text-notify">
                    Please enter the text you would like to see at the top of
                    your QR tag.
                  </p>
                  <TextField
                    required
                    id="qr_label"
                    name="QR Label"
                    label="QR Label Text"
                    fullWidth
                    autoComplete="given-name"
                    variant="outlined"
                    value={labelText}
                    onChange={(e) => setLabelText(e.target.value)}
                    inputProps={{
                      maxLength: 20,
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    sx={{ mb: 2 }}
                  />
                  <p>
                    Enter up to 20 characters to personalize your QR tag or
                    leave "The QR Store" as the default.
                  </p>
                  <div className="button-text">
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-checkout btn-custom-prev-next"
                      onClick={handleStripeGateway}
                      sx={{ mt: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div id="qrlabel-area">
                  <h3 className="qrlabel-preview-text">{labelText}</h3>
                  <img
                    src={`data:image/png;base64,${qrData}`}
                    alt="QR Code"
                    className="qr-preview-img"
                  />
                </div>
              </Grid>
            </Grid>
            <BasicModal
              open={modalOpen}
              title={modalTitle}
              content={modalContent}
              showButton={showButton}
              handleClose={() => {
                setModalOpen(false);
              }}
              handleClick={() => {
                setModalOpen(false);
              }}
            />
          </Container>
        </React.Fragment>
        <ModalSearchSection />
        <ModalMenuSection />
      </div>
    </div>
  );
}
