import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import axiosApiInstance from "../../store/axiosApi";
import Popover from "@mui/material/Popover";
import Pagination from "@mui/material/Pagination";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import TextField from "@mui/material/TextField";
import LoadingDialog from "../Loading";
import BasicModal from "../BasicModal/modal";
import constants from "../../config/constants";
import Footer from "../Footer";

export default function Payment() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(-1);
  const [email, setEmail] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\d+$/;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("Enter your account ID.");
  const [token, setToken] = useState("");

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };

  const [activeTab, setActiveTab] = useState("dashboard");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPaymentMethod == 0) {
      setSelectedLabel("Enter the email linked with your Paypal account.");
    } else if (selectedPaymentMethod == 1) {
      setSelectedLabel(
        "Enter the phone number linked with your Venmo account."
      );
    }
  }, [selectedPaymentMethod]);

  const showStateModal = (str) => {
    setStatetModalShow(true);
    setModalError(str);
  };

  function validateInput() {
    if (selectedPaymentMethod == 0 && emailPattern.test(email)) {
      setErrorInput("");
      return true;
    } else if (selectedPaymentMethod == 1 && phonePattern.test(email)) {
      setErrorInput("");
      return true;
    } else {
      setErrorInput("Invalid Email or Phone number.");
      return false;
    }
  }

  const handleClick = async () => {
    try {
      if (selectedPaymentMethod == -1 || selectedPaymentMethod == null) {
        showModal("Notification", "You must select one payment method.");
        return;
      }
      if (email == "") {
        showModal("Notification", selectedLabel);
        return;
      }
      if (!validateInput()) return;
      setLoading(true);
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");

      await axiosApiInstance.post("/sendMoneyViaPP", {
        // await axiosApiInstance.post("/api/sendMoneyViaPP", {
        token: token,
        email: email,
        selectedPaymentMethod: selectedPaymentMethod,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.msg);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setToken(token);

      const response = await axiosApiInstance.post("/profile", {
        // const response = await axiosApiInstance.post("/api/profile", {
        token: token,
      });
      if (response.data.success) {
        setLoading(false);
        console.log(response.data.user);
        setUserInfo(response.data.user);
        console.log(response.data.user);
        setSelectedPaymentMethod(response.data.user.fund_method);
        setEmail(response.data.user.fund_email);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.msg);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  return (
    <div>
      {loading ? <LoadingDialog /> : <div />}
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={"/img/logo-white.png"} />
          <BreadcrumbSection
            heading={"AFFILIATE DASHBOARD"}
            home={"Home"}
            page={"Dashboard"}
            title={"Your Payment Method"}
            pageLink={`/dashboard?token=${token}`}
            showBreadcrumb="true"
          />
          <Container component="main" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 1, md: 3 } }}
            >
              <React.Fragment>
                <Box sx={{ bgcolor: "background.paper" }}>
                  <h3 style={{ textAlign: "center" }}>
                    Select your Payment Method
                  </h3>
                  <Typography variant="body1" gutterBottom style={{
                    textAlign: "center",
                    paddingTop: "15px"
                  }}>
                  Select your preferred payment platform to receive your commissions.
                  </Typography>

                  <div className="payment-method">
                    <div
                      className={
                        selectedPaymentMethod == 0
                          ? "payment-method-item active"
                          : "payment-method-item"
                      }
                      onClick={(e) => {
                        setSelectedPaymentMethod(0);
                      }}
                    >
                      <img
                        alt=""
                        src="/img/paypal.png"
                        style={{ marginTop: "35px" }}
                      />
                    </div>
                    <div
                      className={
                        selectedPaymentMethod == 1
                          ? "payment-method-item active"
                          : "payment-method-item"
                      }
                      onClick={(e) => {
                        setSelectedPaymentMethod(1);
                      }}
                    >
                      <img
                        alt=""
                        src="/img/venmo.png"
                        style={{ marginTop: "35px" }}
                      />
                    </div>
                  </div>
                  <TextField
                    name="email"
                    label={selectedLabel}
                    fullWidth
                    autoComplete="given-name"
                    variant="outlined"
                    defaultValue={email}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateInput();
                    }}
                    error={!!errorInput}
                    helperText={errorInput}
                  />
                  <Button
                    variant="contained"
                    className="btn-checkout btn-submit"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={handleClick}
                  >
                    Save
                  </Button>

                  <BasicModal
                    open={modalOpen}
                    title={modalTitle}
                    content={modalContent}
                    showButton={false}
                    handleClose={() => {
                      setModalOpen(false);
                    }}
                    handleClick={() => {
                      setModalOpen(false);
                    }}
                  />
                </Box>
              </React.Fragment>
            </Paper>
          </Container>
          <ModalSearchSection />
          <ModalMenuSection />
          <Footer position="absolute" />
        </div>
      </div>
    </div>
  );
}
