import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import validateEmailAndPhone from "../Validate";
import { useSelector, useDispatch } from "react-redux";
import {
  setFirstName,
  setLastName,
  setOrganization,
  setPositionWork,
  setPhoneWork,
  setConfirmPhoneWork,
  setPhoneMobile,
  setConfirmPhoneMobile,
  setEmail,
  setConfirmEmail,
  setWebsite,
  setStreet,
  setZipCode,
  setCity,
  setNaState,
  setCountry,
  setErrors,
  setPersonalLink1Title,
  setPersonalLink1,
  setPersonalLink2Title,
  setPersonalLink2,
  setPersonalLink3Title,
  setPersonalLink3,
  setPerson,
  setAvatar,
} from "../../store/contactInfoSlice";

import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

export default function AddressForm() {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.person);

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (!selectedFile) {
      dispatch(setAvatar(""));
      return;
    }

    const objectURL = window.URL.createObjectURL(selectedFile);
    dispatch(setAvatar(objectURL));
    console.log(objectURL);
    // return () => window.URL.revokeObjectURL(objectURL);
  }, [selectedFile]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("personData"));
    if (savedData) {
      dispatch(setPerson(savedData));
      if (!person.country.trim()) dispatch(setCountry("USA"));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      person.email == "" &&
      person.confirmEmail == "" &&
      person.phoneMobile == "" &&
      person.confirmPhoneMobile == "" &&
      person.phoneWork == "" &&
      person.confirmPhoneWork == ""
    )
      return;
    dispatch(
      setErrors(
        validateEmailAndPhone(
          person.email,
          person.confirmEmail,
          person.phoneMobile,
          person.confirmPhoneMobile,
          person.phoneWork,
          person.confirmPhoneWork
        )
      )
    );
  }, [
    person.phoneMobile,
    person.confirmPhoneMobile,
    person.email,
    person.confirmEmail,
    person.phoneWork,
    person.confirmPhoneWork,
  ]);

  const changePersonalLink1 = (value) => {
    if (value && !/^https?:\/\//i.test(value)) {
      value = `https://www.${value}`;
    }
    // Ensure that "https://www." isn't added if all text is removed
    if (!value.replace(/https:\/\/www\./i, "").length) {
      value = value.replace(/https:\/\/www\./i, "");
    }
    dispatch(setPersonalLink1(value));
  };

  const changePersonalLink2 = (value) => {
    if (value && !/^https?:\/\//i.test(value)) {
      value = `https://www.${value}`;
    }
    // Ensure that "https://www." isn't added if all text is removed
    if (!value.replace(/https:\/\/www\./i, "").length) {
      value = value.replace(/https:\/\/www\./i, "");
    }
    dispatch(setPersonalLink2(value));
  };

  const changePersonalLink3 = (value) => {
    if (value && !/^https?:\/\//i.test(value)) {
      value = `https://www.${value}`;
    }
    // Ensure that "https://www." isn't added if all text is removed
    if (!value.replace(/https:\/\/www\./i, "").length) {
      value = value.replace(/https:\/\/www\./i, "");
    }
    dispatch(setPersonalLink3(value));
  };

  const handleFileChange = (event) => {
    if (event.target.files[0] == undefined) return;
    setSelectedFile(event.target.files[0]);
  };

  const resizeImgFile = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          let newWidth;
          let newHeight;

          if (width > height) {
            newWidth = 350;
            newHeight = undefined;
          } else {
            newWidth = undefined;
            newHeight = 350;
          }

          Resizer.imageFileResizer(
            file,
            newWidth,
            newHeight,
            "JPEG",
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            "base64"
          );
        };
      };
      reader.readAsDataURL(file);
    });

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom className="preview-caption">
        Enter your contact information below
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            className="img-wrap"
            bis_skin_checked="1"
            style={{
              textAlign: "center",
              padding: "20px",
              background: "#fff",
              position: "relative",
              border: "5px solid black",
            }}
          >
            <div className="image-container">
              {person.avatar ? (
                <img
                  className="dynamicQR-img-body"
                  src={person.avatar}
                  alt="User Avatar"
                />
              ) : (
                <Avatar
                  sx={{ width: "100%", height: "100%", bgcolor: "white" }}
                >
                  <PersonIcon style={{ fontSize: "130px", color: "#000" }} />
                </Avatar>
              )}
              <input
                type="file"
                id="file-input"
                style={{ display: "none" }}
                accept=".jpeg, .jpg, .png, image/jpeg, image/png|image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="file-input" className="hover-button">
                {person.avatar ? "Change Picture" : "Upload Picture"}
              </label>
            </div>
          </div>
          <Typography
            variant="body1"
            gutterBottom
            style={{
              textAlign: "center",
            }}
          >
            Please click on the icon above to upload your contact picture.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="outlined"
            value={person.firstName}
            onChange={(e) => dispatch(setFirstName(e.target.value))}
            inputProps={{
              style: {
                backgroundColor: person.firstName ? "white" : "#ffe6e6", // Light pink for required field
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="outlined"
            onChange={(e) => dispatch(setLastName(e.target.value))}
            value={person.lastName}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="organization"
            name="organization"
            label="Organization"
            fullWidth
            autoComplete="organization"
            variant="outlined"
            onChange={(e) => dispatch(setOrganization(e.target.value))}
            value={person.organization}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Position (Work)"
            label="Job Title"
            fullWidth
            autoComplete="position_work"
            variant="outlined"
            onChange={(e) => dispatch(setPositionWork(e.target.value))}
            value={person.positionWork}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="phone_work"
            name="Phone (Work)"
            label="Phone (Work)"
            fullWidth
            autoComplete="phone_work"
            onChange={(e) => dispatch(setPhoneWork(e.target.value))}
            variant="outlined"
            value={person.phoneWork}
            error={!!person.errors.phoneWork}
            helperText={person.errors.phoneWork}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="confirm_phone_work"
            name="Phone (Work)"
            label="Re-enter Phone (Work)"
            fullWidth
            autoComplete="phone_work"
            onChange={(e) => dispatch(setConfirmPhoneWork(e.target.value))}
            variant="outlined"
            value={person.confirmPhoneWork}
            error={!!person.errors.confirmPhoneWork}
            helperText={person.errors.confirmPhoneWork}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="position_work"
            name="Phone (Mobile)"
            label="Phone (Mobile)"
            fullWidth
            autoComplete="position_work"
            onChange={(e) => dispatch(setPhoneMobile(e.target.value))}
            variant="outlined"
            value={person.phoneMobile}
            error={!!person.errors.phoneMobile}
            helperText={person.errors.phoneMobile}
            inputProps={{
              style: {
                backgroundColor: person.phoneMobile ? "white" : "#ffe6e6", // Light pink for required field
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="confirm_mobile_phone"
            name="Re-enter Phone (Mobile)"
            label="Re-enter Phone (Mobile)"
            fullWidth
            onChange={(e) => dispatch(setConfirmPhoneMobile(e.target.value))}
            autoComplete="confirm_mobile_phone"
            variant="outlined"
            value={person.confirmPhoneMobile}
            error={!!person.errors.confirmPhoneMobile}
            helperText={person.errors.confirmPhoneMobile}
            inputProps={{
              style: {
                backgroundColor: person.confirmPhoneMobile
                  ? "white"
                  : "#ffe6e6", // Light pink for required field
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="position_work"
            name="Phone (Mobile)"
            label="Email"
            fullWidth
            onChange={(e) => dispatch(setEmail(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.email}
            error={!!person.errors.email}
            helperText={person.errors.email}
            inputProps={{
              style: {
                backgroundColor: person.email ? "white" : "#ffe6e6", // Light pink for required field
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="position_work"
            name="Phone (Mobile)"
            label="Re-enter Email"
            onChange={(e) => dispatch(setConfirmEmail(e.target.value))}
            fullWidth
            autoComplete="position_work"
            variant="outlined"
            value={person.confirmEmail}
            error={!!person.errors.confirmEmail}
            helperText={person.errors.confirmEmail}
            inputProps={{
              style: {
                backgroundColor: person.confirmEmail ? "white" : "#ffe6e6", // Light pink for required field
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Street"
            fullWidth
            onChange={(e) => dispatch(setStreet(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.street}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="City"
            fullWidth
            onChange={(e) => dispatch(setCity(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.city}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="State"
            fullWidth
            onChange={(e) => dispatch(setNaState(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.naState}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Zip Code"
            fullWidth
            autoComplete="position_work"
            variant="outlined"
            onChange={(e) => dispatch(setZipCode(e.target.value))}
            value={person.zipCode}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Country"
            fullWidth
            onChange={(e) => dispatch(setCountry(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.country}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="website-link-gray">
            <p className="web-link-label">Website Link #1</p>
            <TextField
              className="web-link-title"
              label="Enter text such as 'Click my website link for information about my services'"
              fullWidth
              onChange={(e) => dispatch(setPersonalLink1Title(e.target.value))}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink1Title}
            />
            <TextField
              className="web-link-url"
              label="Enter your website link here"
              fullWidth
              onChange={(e) => changePersonalLink1(e.target.value)}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink1}
            />
          </div>
        </Grid>

        {/* <Grid item xs={12}>
            <TextField
              id="position_work"
              name="Phone (Mobile)"
              label="Enter text such as 'Click my website link for information about my services'"
              fullWidth
              onChange={(e) => dispatch(setPersonalLink1Title(e.target.value))}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink1Title}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="position_work"
              name="Phone (Mobile)"
              label="Enter your website link here"
              fullWidth
              onChange={(e) => changePersonalLink1(e.target.value)}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink1}
            />
          </Grid> */}
        <Grid item xs={12}>
          <div className="website-link">
            <p className="web-link-label">Website Link #2</p>
            <TextField
              className="web-link-title"
              label="Enter text such as 'Follow me on Facebook'"
              fullWidth
              onChange={(e) => dispatch(setPersonalLink2Title(e.target.value))}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink2Title}
            />
            <TextField
              className="web-link-url"
              label="Enter your social media link"
              fullWidth
              onChange={(e) => changePersonalLink2(e.target.value)}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink2}
            />
          </div>
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Enter text such as 'Follow me on Facebook'"
            fullWidth
            onChange={(e) => dispatch(setPersonalLink2Title(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.personalLink2Title}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Enter your social media link"
            fullWidth
            onChange={(e) => changePersonalLink2(e.target.value)}
            autoComplete="position_work"
            variant="outlined"
            value={person.personalLink2}
          />
        </Grid> */}

        <Grid item xs={12}>
          <div className="website-link-gray">
            <p className="web-link-label">Website Link #3</p>
            <TextField
              className="web-link-title"
              label="Enter text such as 'Follow me on Instagram'"
              fullWidth
              onChange={(e) => dispatch(setPersonalLink3Title(e.target.value))}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink3Title}
            />
            <TextField
              className="web-link-url"
              label="Enter your social media link"
              fullWidth
              onChange={(e) => changePersonalLink3(e.target.value)}
              autoComplete="position_work"
              variant="outlined"
              value={person.personalLink3}
            />
          </div>
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Enter text such as 'Follow me on Instagram'"
            fullWidth
            onChange={(e) => dispatch(setPersonalLink3Title(e.target.value))}
            autoComplete="position_work"
            variant="outlined"
            value={person.personalLink3Title}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="position_work"
            name="Phone (Mobile)"
            label="Enter your social media link"
            fullWidth
            onChange={(e) => changePersonalLink3(e.target.value)}
            autoComplete="position_work"
            variant="outlined"
            value={person.personalLink3}
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
