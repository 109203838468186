import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import axiosApiInstance from "../../store/axiosApi";
import Resizer from "react-image-file-resizer";
import Popover from "@mui/material/Popover";
import Pagination from "@mui/material/Pagination";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import ScrollupSection from "../Scrollup/Scrollup";
import Header from "../Header/HeaderOne";
import BreadcrumbSection from "../Breadcrumb/BreadcrumbThree";
import ModalSearchSection from "../Modal/ModalSearch";
import ModalMenuSection from "../Modal/ModalMenu";
import TextField from "@mui/material/TextField";
import LoadingDialog from "../Loading";
import { useSelector, useDispatch } from "react-redux";
import validateEmailAndPhone from "../Validate";
import constants from "../../config/constants";
import {
  setFirstName,
  setLastName,
  setOrganization,
  setPositionWork,
  setPhoneWork,
  setConfirmPhoneWork,
  setPhoneMobile,
  setConfirmPhoneMobile,
  setEmail,
  setConfirmEmail,
  setWebsite,
  setStreet,
  setZipCode,
  setCity,
  setNaState,
  setCountry,
  setErrors,
  setPersonalLink1Title,
  setPersonalLink1,
  setPersonalLink2Title,
  setPersonalLink2,
  setPersonalLink3Title,
  setPersonalLink3,
  setPerson,
  setAvatar,
  setActiveStep,
} from "../../store/contactInfoSlice";
import BasicModal from "../BasicModal/modal";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Footer from "../Footer";
import ContactPreview from "../AddressForm/ContactPreview";

export default function DashboardContact() {
  const [token, setToken] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("dashboard");
  const dispatch = useDispatch();
  const person = useSelector((state) => state.person);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const [isMobileView, setIsMobileView] = useState(false);
  const [activeMobileView, setActiveMobileView] = useState("content");
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!selectedFile) {
      dispatch(setAvatar(""));
      return;
    }

    const objectURL = window.URL.createObjectURL(selectedFile);
    dispatch(setAvatar(objectURL));
    console.log(objectURL);
    return () => window.URL.revokeObjectURL(objectURL);
  }, [selectedFile]);

  const showModal = (title, content) => {
    setModalOpen(true);
    setModalTitle(title);
    setModalContent(content);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const changePersonalLink1 = (value) => {
    if (value && !/^https?:\/\//i.test(value)) {
      value = `https://www.${value}`;
    }
    // Ensure that "https://www." isn't added if all text is removed
    if (!value.replace(/https:\/\/www\./i, "").length) {
      value = value.replace(/https:\/\/www\./i, "");
    }
    dispatch(setPersonalLink1(value));
  };

  const changePersonalLink2 = (value) => {
    if (value && !/^https?:\/\//i.test(value)) {
      value = `https://www.${value}`;
    }
    // Ensure that "https://www." isn't added if all text is removed
    if (!value.replace(/https:\/\/www\./i, "").length) {
      value = value.replace(/https:\/\/www\./i, "");
    }
    dispatch(setPersonalLink2(value));
  };

  const changePersonalLink3 = (value) => {
    if (value && !/^https?:\/\//i.test(value)) {
      value = `https://www.${value}`;
    }
    // Ensure that "https://www." isn't added if all text is removed
    if (!value.replace(/https:\/\/www\./i, "").length) {
      value = value.replace(/https:\/\/www\./i, "");
    }
    dispatch(setPersonalLink3(value));
  };

  const handleFileChange = (event) => {
    if (event.target.files[0] == undefined) return;
    setSelectedFile(event.target.files[0]);
  };

  const resizeImgFile = (fileOrUrl) =>
    new Promise((resolve, reject) => {
      if (typeof fileOrUrl === "string") {
        // Handle URL case
        fetch(fileOrUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const fileType = blob.type.includes("png") ? "PNG" : "JPEG";
            Resizer.imageFileResizer(
              blob,
              350,
              350,
              fileType,
              100,
              0,
              (uri) => {
                resolve(uri);
              },
              "base64"
            );
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // Handle File case
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            let newWidth;
            let newHeight;

            if (width > height) {
              newWidth = 350;
              newHeight = undefined;
            } else {
              newWidth = undefined;
              newHeight = 350;
            }

            const fileType = fileOrUrl.type.includes("png") ? "PNG" : "JPEG";
            Resizer.imageFileResizer(
              fileOrUrl,
              newWidth,
              newHeight,
              fileType,
              100,
              0,
              (uri) => {
                resolve(uri);
              },
              "base64"
            );
          };
        };
        reader.readAsDataURL(fileOrUrl);
      }
    });

  const handleClick = async () => {
    try {
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");

      if (!person.firstName.trim()) {
        showModal("Notification", "Please enter your first name.");
        return;
      }

      if (!person.phoneMobile.trim()) {
        showModal("Notification", "Please enter your phone number.");
        return;
      }

      if (person.phoneMobile !== person.confirmPhoneMobile) {
        showModal("Notification", "Please confirm your Phone (Mobile).");
        return;
      }

      if (!person.email.trim()) {
        showModal("Notification", "Please enter your email.");
        return;
      }

      if (person.email !== person.confirmEmail) {
        showModal("Notification", "Please confirm your Email.");
        return;
      }

      const errors = validateEmailAndPhone(
        person.email,
        person.confirmEmail,
        person.phoneMobile,
        person.confirmPhoneMobile,
        person.phoneWork,
        person.confirmPhoneWork
      );

      if (
        errors.email != "" ||
        errors.confirmEmail != "" ||
        errors.phoneMobile != "" ||
        errors.confirmPhoneMobile != "" ||
        errors.phoneWork != "" ||
        errors.confirmPhoneWork != ""
      ) {
        showModal("Notification", "Please enter the correct value.");
        return;
      }

      let avatarImage = "";
      if (person.avatar != undefined && person.avatar != "") {
        const binaryData = await resizeImgFile(person.avatar);
        avatarImage = binaryData;
      }

      console.log(`555: ${avatarImage}`);
      const formData = {
        firstName: person.firstName,
        lastName: person.lastName,
        orgName: person.organization,
        positionWork: person.positionWork,
        phoneWork: person.phoneWork,
        phoneMobile: person.phoneMobile,
        email: person.email,
        website: person.website,
        street: person.street,
        zipCode: person.zipCode,
        city: person.city,
        naState: person.naState,
        country: person.country,
        personalLink1:
          person.personalLink1 != "" && !person.personalLink1.startsWith("http")
            ? "https://" + person.personalLink1
            : person.personalLink1,
        personalLink1Title: person.personalLink1Title,
        personalLink2:
          person.personalLink2 != "" && !person.personalLink2.startsWith("http")
            ? "https://" + person.personalLink2
            : person.personalLink2,
        personalLink2Title: person.personalLink2Title,
        personalLink3:
          person.personalLink3 != "" && !person.personalLink3.startsWith("http")
            ? "https://" + person.personalLink3
            : person.personalLink3,
        personalLink3Title: person.personalLink3Title,
        image: avatarImage,
      };

      setLoading(true);
      const response = await axiosApiInstance.post("/setProfile", {
        profile: formData,
        token: token,
      });
      setLoading(false);

      if (response.data.success) {
        showModal("Notification", response.data.message);
      } else {
        showModal("Notification", response.data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.message);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  useEffect(() => {
    if (
      person.email == "" &&
      person.confirmEmail == "" &&
      person.phoneMobile == "" &&
      person.confirmPhoneMobile == "" &&
      person.phoneWork == "" &&
      person.confirmPhoneWork == ""
    )
      return;
    dispatch(
      setErrors(
        validateEmailAndPhone(
          person.email,
          person.confirmEmail,
          person.phoneMobile,
          person.confirmPhoneMobile,
          person.phoneWork,
          person.confirmPhoneWork
        )
      )
    );
  }, [
    person.phoneMobile,
    person.confirmPhoneMobile,
    person.email,
    person.confirmEmail,
    person.phoneWork,
    person.confirmPhoneWork,
  ]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const token = queryParams.get("token");
      setToken(token);

      const response = await axiosApiInstance.post("/profile", {
        token: token,
      });
      if (response.data.success) {
        setLoading(false);
        console.log(response.data.user);
        dispatch(setPerson(response.data.user));
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response != null && error.response.status == 401)
        showModal("Notification", error.response.data.message);
      else showModal("Notification", constants.serverConnectionErrMsg);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const contactPreview = document.getElementById("contactPreview");
      const extraButtonWrapper = document.getElementsByClassName(
        "extra_button_wrapper"
      )[0];
      const qrPagePrev = document.getElementById("qr_page_prev");
      const scrollTop = window.scrollY; // Use window.scrollY to get the scroll position
      const screenWidth = window.innerWidth;

      if (screenWidth <= 768) {
        if (scrollTop >= 15) {
          contactPreview.style.top = `${250 - scrollTop}px`;
          extraButtonWrapper.style.bottom = `calc(100vh - ${
            935 - scrollTop
          }px)`;
        } else {
          contactPreview.style.top = `${250 - scrollTop}px`;
          extraButtonWrapper.style.bottom = `calc(100vh - 920px)`;
        }
      } else {
        if (scrollTop >= 250) {
          contactPreview.style.top = "0"; // Change this to your specific value
          extraButtonWrapper.style.bottom = `calc(100vh - 725px)`;
        } else {
          contactPreview.style.top = `${250 - scrollTop}px`; // Original value or another value when scrolled less than 300
          extraButtonWrapper.style.bottom = `calc(100vh - ${
            975 - scrollTop
          }px)`;
        }
      }

      const maxHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const maxQrPagePrevScroll =
        qrPagePrev.scrollHeight - qrPagePrev.clientHeight;

      // Calculate the new scroll position for qr_page_prev
      const newScrollPosition = (scrollTop / maxHeight) * maxQrPagePrevScroll;

      qrPagePrev.scrollTo({
        top: newScrollPosition,
        behavior: "smooth",
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {loading ? <LoadingDialog /> : <div />}
      <div>
        <ScrollupSection />
        <div className="main overflow-hidden">
          <Header imageData={"/img/logo-white.png"} />
          <BreadcrumbSection
            heading={"AFFILIATE DASHBOARD"}
            home={"Home"}
            page={"Dashboard"}
            title={"Your Contact Information"}
            pageLink={`/dashboard?token=${token}`}
            showBreadcrumb="true"
          />
          <Container
            component="main"
            sx={{ mb: 4, minHeight: "100vh" }}
            className="dashboard"
          >
            <Grid
              container
              spacing={2}
              sx={{ display: { xs: "block", sm: "flex" } }}
            >
              <Grid
                item
                xs={12}
                sm={8}
                sx={{
                  display:
                    activeMobileView === "content" || !isMobileView
                      ? "block"
                      : "none",
                  px: { xs: 1, sm: 2 },
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{ my: { xs: 3, md: 2 }, p: { xs: 2, md: 3 } }}
                >
                  <React.Fragment>
                    <Box sx={{ bgcolor: "background.paper" }}>
                      <Typography
                        variant="h5"
                        gutterBottom
                        className="preview-caption"
                      >
                        View/Edit Your Contact
                      </Typography>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <div
                            className="img-wrap"
                            bis_skin_checked="1"
                            style={{
                              textAlign: "center",
                              padding: "20px",
                              background: "#fff",
                              position: "relative",
                              border: "5px solid black",
                            }}
                          >
                            <div className="image-container">
                              {person.avatar ? (
                                <img
                                  className="dynamicQR-img-body"
                                  src={person.avatar}
                                  alt="User Avatar"
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    bgcolor: "white",
                                  }}
                                >
                                  <PersonIcon
                                    style={{ fontSize: "130px", color: "#000" }}
                                  />
                                </Avatar>
                              )}
                              <input
                                type="file"
                                id="file-input"
                                style={{ display: "none" }}
                                accept=".jpeg, .jpg, .png, image/jpeg, image/png|image/*"
                                onChange={handleFileChange}
                              />
                              <label
                                htmlFor="file-input"
                                className="hover-button"
                              >
                                {person.avatar
                                  ? "Change Picture"
                                  : "Upload Picture"}
                              </label>
                            </div>
                          </div>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Please click on the icon above to upload your
                            contact picture.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="firstName"
                            name="firstName"
                            label="First name"
                            fullWidth
                            autoComplete="given-name"
                            variant="outlined"
                            value={person.firstName}
                            onChange={(e) =>
                              dispatch(setFirstName(e.target.value))
                            }
                            inputProps={{
                              style: {
                                backgroundColor: person.firstName
                                  ? "white"
                                  : "#ffe6e6", // Light pink for required field
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            fullWidth
                            autoComplete="family-name"
                            variant="outlined"
                            onChange={(e) =>
                              dispatch(setLastName(e.target.value))
                            }
                            value={person.lastName}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="organization"
                            name="organization"
                            label="Organization"
                            fullWidth
                            autoComplete="organization"
                            variant="outlined"
                            onChange={(e) =>
                              dispatch(setOrganization(e.target.value))
                            }
                            value={person.organization}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="position_work"
                            name="Position (Work)"
                            label="Job Title"
                            fullWidth
                            autoComplete="position_work"
                            variant="outlined"
                            onChange={(e) =>
                              dispatch(setPositionWork(e.target.value))
                            }
                            value={person.positionWork}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="phone_work"
                            name="Phone (Work)"
                            label="Phone (Work)"
                            fullWidth
                            autoComplete="phone_work"
                            onChange={(e) =>
                              dispatch(setPhoneWork(e.target.value))
                            }
                            variant="outlined"
                            value={person.phoneWork}
                            error={!!person.errors.phoneWork}
                            helperText={person.errors.phoneWork}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="confirm_phone_work"
                            name="Phone (Work)"
                            label="Re-enter Phone (Work)"
                            fullWidth
                            autoComplete="phone_work"
                            onChange={(e) =>
                              dispatch(setConfirmPhoneWork(e.target.value))
                            }
                            variant="outlined"
                            value={person.confirmPhoneWork}
                            error={!!person.errors.confirmPhoneWork}
                            helperText={person.errors.confirmPhoneWork}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="position_work"
                            name="Phone (Mobile)"
                            label="Phone (Mobile)"
                            fullWidth
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.phoneMobile}
                            error={!!person.errors.phoneMobile}
                            helperText={person.errors.phoneMobile}
                            inputProps={{
                              style: {
                                backgroundColor: person.phoneMobile
                                  ? "white"
                                  : "#ffe6e6", // Light pink for required field
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="confirm_mobile_phone"
                            name="Re-enter Phone (Mobile)"
                            label="Re-enter Phone (Mobile)"
                            fullWidth
                            autoComplete="confirm_mobile_phone"
                            variant="outlined"
                            value={person.confirmPhoneMobile}
                            error={!!person.errors.confirmPhoneMobile}
                            helperText={person.errors.confirmPhoneMobile}
                            inputProps={{
                              style: {
                                backgroundColor: person.confirmPhoneMobile
                                  ? "white"
                                  : "#ffe6e6", // Light pink for required field
                              },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="position_work"
                            name="Phone (Mobile)"
                            label="Email"
                            fullWidth
                            onChange={(e) => dispatch(setEmail(e.target.value))}
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.email}
                            error={!!person.errors.email}
                            helperText={person.errors.email}
                            inputProps={{
                              style: {
                                backgroundColor: person.email
                                  ? "white"
                                  : "#ffe6e6", // Light pink for required field
                              },
                            }}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="position_work"
                            name="Phone (Mobile)"
                            label="Re-enter Email"
                            onChange={(e) =>
                              dispatch(setConfirmEmail(e.target.value))
                            }
                            fullWidth
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.confirmEmail}
                            error={!!person.errors.confirmEmail}
                            helperText={person.errors.confirmEmail}
                            disabled={true}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="position_work"
                            name="Phone (Mobile)"
                            label="Street"
                            fullWidth
                            onChange={(e) =>
                              dispatch(setStreet(e.target.value))
                            }
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.street}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="position_work"
                            name="Phone (Mobile)"
                            label="Zip Code"
                            fullWidth
                            autoComplete="position_work"
                            variant="outlined"
                            onChange={(e) =>
                              dispatch(setZipCode(e.target.value))
                            }
                            value={person.zipCode}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="position_work"
                            name="Phone (Mobile)"
                            label="City"
                            fullWidth
                            onChange={(e) => dispatch(setCity(e.target.value))}
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.city}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="position_work"
                            name="Phone (Mobile)"
                            label="State"
                            fullWidth
                            onChange={(e) =>
                              dispatch(setNaState(e.target.value))
                            }
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.naState}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="position_work"
                            name="Phone (Mobile)"
                            label="Country"
                            fullWidth
                            onChange={(e) =>
                              dispatch(setCountry(e.target.value))
                            }
                            autoComplete="position_work"
                            variant="outlined"
                            value={person.country}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <div className="website-link-gray">
                            <p className="web-link-label">Website Link #1</p>
                            <TextField
                              className="web-link-title"
                              label="Enter text such as 'Click my website link for information about my services'"
                              fullWidth
                              onChange={(e) =>
                                dispatch(setPersonalLink1Title(e.target.value))
                              }
                              autoComplete="position_work"
                              variant="outlined"
                              value={person.personalLink1Title}
                            />
                            <TextField
                              className="web-link-url"
                              label="Enter your website link here"
                              fullWidth
                              onChange={(e) =>
                                changePersonalLink1(e.target.value)
                              }
                              autoComplete="position_work"
                              variant="outlined"
                              value={person.personalLink1}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="website-link">
                            <p className="web-link-label">Website Link #2</p>
                            <TextField
                              className="web-link-title"
                              label="Enter text such as 'Follow me on Facebook'"
                              fullWidth
                              onChange={(e) =>
                                dispatch(setPersonalLink2Title(e.target.value))
                              }
                              autoComplete="position_work"
                              variant="outlined"
                              value={person.personalLink2Title}
                            />
                            <TextField
                              className="web-link-url"
                              label="Enter your social media link"
                              fullWidth
                              onChange={(e) =>
                                changePersonalLink2(e.target.value)
                              }
                              autoComplete="position_work"
                              variant="outlined"
                              value={person.personalLink2}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="website-link-gray">
                            <p className="web-link-label">Website Link #3</p>
                            <TextField
                              className="web-link-title"
                              label="Enter text such as 'Follow me on Instagram'"
                              fullWidth
                              onChange={(e) =>
                                dispatch(setPersonalLink3Title(e.target.value))
                              }
                              autoComplete="position_work"
                              variant="outlined"
                              value={person.personalLink3Title}
                            />
                            <TextField
                              className="web-link-url"
                              label="Enter your social media link"
                              fullWidth
                              onChange={(e) =>
                                changePersonalLink3(e.target.value)
                              }
                              autoComplete="position_work"
                              variant="outlined"
                              value={person.personalLink3}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <Button
                        variant="contained"
                        className="btn-checkout btn-submit"
                        sx={{ mt: 3, ml: 1 }}
                        onClick={handleClick}
                      >
                        Save
                      </Button>
                      <BasicModal
                        open={modalOpen}
                        title={modalTitle}
                        content={modalContent}
                        showButton={true}
                        handleClose={() => {
                          setModalOpen(false);
                        }}
                        handleClick={() => {
                          setModalOpen(false);
                        }}
                      />
                    </Box>
                  </React.Fragment>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                id="contactPreview"
                sx={{
                  display:
                    activeMobileView === "preview" || !isMobileView
                      ? "block"
                      : "none",
                  position: { sm: "fixed" },
                  top: { sm: "250px" },
                  left: { sm: "calc(50% + 205px)" },
                }}
              >
                <ContactPreview />
              </Grid>
            </Grid>
          </Container>

          <Box
            sx={{
              display: { xs: "block", sm: "none" },
              position: "fixed",
              bottom: "0",
              width: "100%",
              backgroundColor: "#fff",
              zIndex: 1000,
              boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
            }}
          >
            <Button
              variant={
                activeMobileView === "content" ? "contained" : "outlined"
              }
              onClick={() => setActiveMobileView("content")}
              sx={{ width: "50%" }}
            >
              Content
            </Button>
            <Button
              variant={
                activeMobileView === "preview" ? "contained" : "outlined"
              }
              onClick={() => setActiveMobileView("preview")}
              sx={{ width: "50%" }}
            >
              Preview
            </Button>
          </Box>

          <ModalSearchSection />
          <ModalMenuSection />
          <Footer />
        </div>
      </div>
    </div>
  );
}
