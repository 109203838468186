import React, { Component, useState, useEffect } from "react";
import CouponOne from "../../assets/CouponOne.jpg";
import PromoPng from "../../assets/dq-promo.png";
import { Container, Button } from "@mui/material";
import axiosApiInstance from "../../store/axiosApi";
import LoadingDialog from "../Loading";

export default function Coupon() {
  const [isLoading, setLoading] = useState(false);
  const address = "1688 Scenic Gulf Drive, Miramar Beach, FL 32550";
  const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  const handleClick = async () => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.post("/redeem_coupon", {});
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center" }}>
      {isLoading ? <LoadingDialog title="" /> : <div />}
      <br/>
      <img src={PromoPng} alt="Coupon" style={{ width: "100%" }} />
      <div>
        <br/>
        <h4>{address}</h4>
        <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">View on Google Maps</a>
      </div>
      <Button
        className="btn-checkout btn-submit"
        variant="contained"
        component="label"
        color="primary"
        onClick={handleClick}
        style={{ marginTop: "25px", marginBottom: "25px" }}
      >
        REDEEM
      </Button>
    </Container>
  );
}
