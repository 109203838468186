import {
    configureStore
} from '@reduxjs/toolkit';
import contactInfoReducer from './contactInfoSlice'

export default configureStore({
    reducer: {
        person: contactInfoReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
});