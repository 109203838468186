import React, { useState, useEffect, useMemo } from "react";
import { TextField, Button, Typography, Box, Grid } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // date-range picker styles
import "react-date-range/dist/theme/default.css"; // date-range picker theme
// import moment from "moment";
import axiosApiInstance from "../../store/axiosApi";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const images = [
  "/img/purely_logo.png",
  "/img/K0.jpg",
  "/img/K1.jpg",
  "/img/K2.jpg",
  "/img/K3.jpg",
  "/img/K4.jpg",
  "/img/K5.jpg",
  "/img/K6.jpg",
  "/img/K7.jpg",
  "/img/K8.jpg",
  "/img/K9.jpg",
];

const CarouselComponent = React.memo(() => (
  <Grid container spacing={2} item xs={12} md={12}>
    <Grid item xs={12}>
      <Slider
        dots
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplay
      >
        {images.map((src, index) => (
          <div key={index}>
            <img
              src={src}
              alt={`Carousel image ${index}`}
              style={{ width: "100%", height: "300px", borderRadius: "10px" }}
            />
          </div>
        ))}
      </Slider>
    </Grid>
  </Grid>
));

// Random images memoized to avoid rerenders
const RandomImagesComponent = React.memo(({ randomImages, isMobile }) => (
  <Grid container item spacing={2} style={{ marginTop: "20px" }}>
    {randomImages.map((src, index) => (
      <Grid item xs={isMobile ? 12 : 6} key={index}>
        <img
          src={src}
          alt={`Popular ${index}`}
          style={{ width: "100%", height: "200px", borderRadius: "10px" }}
        />
      </Grid>
    ))}
  </Grid>
));

const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000, // Auto close after 5 seconds
    closeOnClick: true, // Close when clicked
    pauseOnHover: true, // Pause auto close when hovered
    draggable: true, // Enable draggable toast
    hideProgressBar: false, // Show progress bar
  });
};

// Error Toast
const showErrorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000, // Auto close after 5 seconds
    closeOnClick: true, // Close when clicked
    pauseOnHover: true, // Pause auto close when hovered
    draggable: true, // Enable draggable toast
    hideProgressBar: false, // Show progress bar
  });
};

export default function BookingForm({ rental }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [guests, setGuests] = useState(1);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [isChecking, setIsChecking] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [loading, setLoading] = useState(false);

  const randomImages = useMemo(
    () => images.sort(() => 0.5 - Math.random()).slice(0, 4),
    [images]
  );

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1280); // Set to true for mobile views (768px and below)
  };

  useEffect(() => {
    handleResize();
    checkAvailability();
    window.addEventListener("resize", handleResize); // Check on window resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDateChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    const diffDays = moment(endDate).diff(moment(startDate), "days");

    if (diffDays < 3) {
      showErrorToast("Minimum stay is 3 nights");
      setStartDate(endDate); // Reset to the selected start date
      setEndDate(endDate); // Clear the end date
    } else {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^[0-9]{10,15}$/; // Adjust the regex based on your phone format requirements
    return re.test(phone);
  };

  // Fetch unavailable dates when the user clicks "Check Availability"
  const checkAvailability = async () => {
    setIsChecking(true);
    setLoading(true);
    try {
      const response = await axiosApiInstance.post("/get-unavailable-dates", {
        rental,
      });
      const unavailableRanges = response.data;

      // Process unavailable ranges and convert the 'start' and 'end' into Date objects in EST
      const unavailableDates = unavailableRanges.flatMap((range) => {
        const startDate = moment.tz(range.start, "America/New_York").toDate(); // Convert to EST
        let endDate = moment.tz(range.end, "America/New_York").toDate(); // Convert to EST

        // Disable the end date since users leave on this date, subtract one day
        endDate.setDate(endDate.getDate() - 1);

        // If the start and end are the same day, consider the full day as unavailable
        if (startDate.getTime() === endDate.getTime()) {
          return [startDate];
        } else {
          // Return all dates in the range as unavailable
          const datesInRange = [];
          let currentDate = new Date(startDate);
          while (currentDate <= endDate) {
            datesInRange.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
          }
          return datesInRange;
        }
      });

      setUnavailableDates(unavailableDates); // Update the unavailable dates in the state
      setIsChecking(false);
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
      setIsChecking(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (
      !startDate ||
      !endDate ||
      !email ||
      !phone ||
      guests < 1 ||
      guests > 4
    ) {
      showErrorToast("Please fill all fields correctly.");
      return;
    }

    if (!validateEmail(email)) {
      showErrorToast("Please enter a valid email.");
      return;
    }

    if (!validatePhone(phone)) {
      showErrorToast("Please enter a valid phone number.");
      return;
    }

    setLoading(true);
    // Prepare booking data
    const bookingData = {
      email: email,
      phone: phone,
      startDate: moment(startDate).format("YYYY-MM-DD"), // Formatting the date for backend
      endDate: moment(endDate).format("YYYY-MM-DD"),
      guests: guests,
      rental: rental,
    };

    try {
      const response = await axiosApiInstance.post("/book-purely", bookingData);

      if (response.status === 200) {
        showSuccessToast("Request Sent!");
        setTimeout(() => {
          location.reload();
        }, 3000);
      } else {
        showErrorToast("Failed to submit booking. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
      showErrorToast(
        "Failed to submit booking. Please check your internet connection."
      );
    } finally {
      setLoading(false); // Hide loading
    }
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const LoadingSpinner = () => (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="flex flex-col items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        <p className="text-white mt-4 text-lg">Loading...</p>
      </div>
    </div>
  );

  return (
    <Container className="my-20">
      {loading && <LoadingSpinner />}
      <ToastContainer />
      <Grid container spacing={3}>
        {/* Image section - Carousel and grid */}
        <Grid item xs={12} md={5} className="image-section">
          <CarouselComponent images={images} />

          {!isMobile && (
            <RandomImagesComponent
              randomImages={randomImages}
              isMobile={isMobile}
            />
          )}

          <Button
            onClick={() => setShowAllImages(!showAllImages)}
            fullWidth
            variant="outlined"
            style={{ marginTop: "20px" }}
          >
            {showAllImages ? "Hide Images" : "Show All Images"}
          </Button>

          {/* Show All Images Grid */}
          {showAllImages && (
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              {images.map((src, index) => (
                <Grid item xs={isMobile ? 12 : 4} key={index}>
                  <img
                    src={src}
                    alt={`All Image ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        {/* Booking form and calendar */}
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              padding: "20px",
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {rental == "demo" ? (
              <Typography variant="h4" align="center" gutterBottom>
                Book Your Stay at Demo
              </Typography>
            ) : (
              <Typography variant="h4" align="center" gutterBottom>
                Book Your Stay at Purely Palms
              </Typography>
            )}

            <DateRangePicker
              ranges={[{ startDate, endDate, key: "selection" }]}
              onChange={handleDateChange}
              months={isMobile ? 1 : 2} // Show 1 month on mobile, 2 on desktop
              showDateDisplay={true}
              direction="horizontal"
              disabledDates={unavailableDates}
              minDate={new Date()}
            />

            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <TextField
              label="Phone"
              fullWidth
              margin="normal"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />

            <TextField
              label="Number of Guests"
              type="number"
              fullWidth
              margin="normal"
              value={guests}
              onChange={(e) => setGuests(e.target.value)}
              InputProps={{ inputProps: { min: 1, max: 4 } }}
              required
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: "20px" }}
              onClick={handleSubmit}
            >
              Submit Request
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
