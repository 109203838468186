import * as React from 'react';

import ScrollupSection from '../Scrollup/Scrollup';
import Header from '../Header/HeaderOne';
import BreadcrumbSection from '../Breadcrumb/BreadcrumbOne';
import ModalSearchSection from '../Modal/ModalSearch';
import ModalMenuSection from '../Modal/ModalMenu';
import AffiliateGuide from './AffiliateGuide';

export default function AffiliateProgram () {
  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={'/img/logo-white.png'} />
        <BreadcrumbSection
          heading={'AFFILIATE PROGRAM'}
          home={'Home'}
          page={'AFFILIATE PROGRAM'}
          title={'AFFILIATE PROGRAM'}
        />
        <AffiliateGuide />
        <ModalSearchSection />
        <ModalMenuSection />
      </div>
    </div>
  );
}
