import React, { useState, useEffect, useCallback } from "react";
import ScrollupSection from "./Scrollup/Scrollup";
import Header from "./Header/HeaderOne";
import BreadcrumbSection from "./Breadcrumb/BreadcrumbThree";
import ModalMenuSection from "./Modal/ModalMenu";
import LoadingDialog from "./Loading";
import axiosApiInstance from "../store/axiosApi";
import Link from "@mui/material/Link";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import constants from "../config/constants";
import Footer from "./Footer";

export default function StripeV1() {
  const [customQrPreview, setCustomQrPreview] = useState();
  const [qrError, setQRError] = useState(false);
  const [labelText, setLabelText] = useState("");
  const [qrErrorMessage, setQRErrorMessage] = useState(
    "Something went wrong.Please click the refresh button."
  );

  const navigate = useNavigate();

  // Function to handle the navigation
  const goToHome = () => {
    navigate("/");
  };

  const fetchData = async () => {
    try {
      const queryString = window.location.search;
      // Create a URLSearchParams object to parse the query string
      const queryParams = new URLSearchParams(queryString);
      // Get the value of a specific query parameter
      const session_id = queryParams.get("session_id");
      const userId = queryParams.get("userid");
      const delta = queryParams.get("delta");
      if (delta == "undefined") delta = "right";

      setQRError(false);
      const postResponse = await axiosApiInstance.post("/verify_stripe", {
        session_id: session_id,
        userId: userId,
        delta: delta,
      });
      if (postResponse.data.status == "error") {
        setQRError(true);
        return;
      }
      setLabelText(postResponse.data.title);
      setQRError(false);
      setCustomQrPreview(postResponse.data.imgData);
    } catch (error) {
      setQRError(true);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();

    localStorage.removeItem("personData");
  }, []);

  return (
    <div>
      <ScrollupSection />
      <div className="main overflow-hidden">
        <Header imageData={"/img/logo-white.png"} />

        <BreadcrumbSection
          heading={"PURCHASE COMPLETED!"}
          home={"Home"}
          page={"Purchase Page"}
          title={"PURCHASE"}
        />

        <React.Fragment>
          <CssBaseline />
          <Container
            component="main"
            maxWidth="md"
            sx={{ mb: 4 }}
            className="mainContainer"
          >
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <React.Fragment>
                {!qrError ? (
                  <div style={{ textAlign: "center" }}>
                    {customQrPreview ? (
                      <div>
                        <p className="qr-code-purchase-note-text">
                          Thanks for your QR code purchase! You can download
                          this high-resolution image of your dynamic QR code
                          right now and save it to an album on your phone for
                          easy access. We'll also be creating and sending your
                          personalized, engraved QR code tag shortly. You can
                          attach it to the back of your phone where its handy to
                          easily share your contact information. If you would
                          like to participate in our Affiliate Marketing
                          Program, click the Home button below and click
                          "Affiliate Login" at the bottom of the Home page.
                          Enter your email and then the link we email you to
                          your Affiliate Dashboard
                        </p>
                        <div id="qrlabel-area">
                          <h3 className="qrlabel-preview-text">{labelText}</h3>
                          <img
                            src={customQrPreview}
                            style={{
                              width: 350,
                            }}
                            alt="processed"
                            title="processed"
                            className="qr-preview-img"
                          />
                        </div>
                        <div>
                          {/* <a
                                href={customQrPreview}
                                download="MyQR.jpg"
                                className="btn btn-success btn-submit"
                                style={{
                                  width: '200px',
                                  color: 'white',
                                  marginBottom: '15px',
                                  marginTop: '15px',
                                }}
                              >
                                Download Image
                              </a> */}
                          <Button
                            variant="contained"
                            color="success"
                            component="a"
                            className="text-smallcase"
                            href={customQrPreview}
                            download="MyQR.jpg"
                            style={{
                              width: "200px",
                              marginBottom: "15px",
                              marginTop: "15px",
                            }}
                          >
                            Download Image
                          </Button>
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          className="text-smallcase"
                          style={{
                            width: "200px",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                          onClick={goToHome} // Set the click handler to your navigation function
                        >
                          Home
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <LoadingDialog title="Thanks for your purchase.....Generating your QR" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p>{constants.qrGenerationErrMsg}</p>

                    <Button
                      onClick={() => fetchData()}
                      variant="contained"
                      style={{
                        width: "200px",
                        color: "white",
                        marginBottom: "15px",
                        marginTop: "15px",
                      }}
                      color="error"
                    >
                      Refresh
                    </Button>
                  </div>
                )}
              </React.Fragment>
            </Paper>
          </Container>
        </React.Fragment>

        <ModalMenuSection />
        <Footer />
      </div>
    </div>
  );
}
